import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
  style = {},
  fill = '#444',
  // width = "100%",
  height = '100%',
  className = '',
  viewBox = '0 0 24 24'
}) => (
  <svg width={height}
    style={style}
    height={height}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}
  ><desc>Classification</desc>
    <g id="class_rd" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-7" transform="translate(2.000000, 5.000000)" fill={`${fill}`} fillRule="nonzero">
        <g id="Group-6">
          <rect id="path-7" x="11" y="0" width="9" height="2" rx="1"></rect>
          <path d="M0.0131696981,0.564251289 C0.171837908,0.188083763 0.336747912,0 0.50789971,0 L7.96641347,0 C8.05754045,-2.25007023e-16 8.13141347,0.0738730163 8.13141347,0.165 C8.13141347,0.193588128 8.12398569,0.221685767 8.1098581,0.246539181 L6.50102179,3.07682183 L6.50102179,3.07682183 C6.43021857,3.18920793 6.43021857,3.33879195 6.50102179,3.45117805 L7.90318805,6.77079829 C7.93864568,6.85474401 7.89933831,6.95153954 7.81539259,6.98699717 C7.79507609,6.99557861 7.77324539,7 7.75119088,7 L2,7 L2,7 L2,14 C2,14.5522847 1.55228475,15 1,15 C0.44771525,15 1.84399221e-15,14.5522847 0,14 L0,0.628293612 C0.000215717624,0.606295514 0.00462028227,0.584520079 0.0131696981,0.564251289 Z" id="CLASSIFICATION_ICON"></path>
        </g>
      </g>
      <rect id="path-7" fill={`${fill}`} fillRule="nonzero" x="13" y="10" width="9" height="2" rx="1"></rect>
      <rect id="path-7" fill={`${fill}`} fillRule="nonzero" x="9" y="15" width="13" height="2" rx="1"></rect>
    </g>
  </svg>
);

SVG.propTypes = {
  style: PropTypes.any,
  fill: PropTypes.any,
  height: PropTypes.any,
  className: PropTypes.any,
  viewBox: PropTypes.any
};

export default SVG;
