import Amplify, { Auth, Storage, Cache, API } from 'aws-amplify';
import config from '../aws-exports.json';

export async function getRequestHeaders (headers) {
  const currentSession = await Auth.currentSession();
  const header = {
    headers: Object.assign({}, {
      'Bs-Token': currentSession.idToken.jwtToken
    }, headers)
  };
  return header;
}

export async function createVerifierRequestHeaders (profileName) {
  const session = await Auth.currentSession();
  const tokenPayload = session.getIdToken().decodePayload();
  const options = {
    headers: Object.assign({}, {
      'Content-Type': 'application/json',
      'X-Tenant-Id': `${tokenPayload.logon.toUpperCase()}_${profileName}`,
      authorization: `bearer ${JSON.stringify({ idToken: session.getIdToken().getJwtToken(), accessToken: session.getAccessToken().getJwtToken(), refreshToken: session.getRefreshToken().getToken() })}`
    }
    )
  };
  return options;
}

export function configureStorage (tenantId) {
  Storage.configure({
    customPrefix: {
      public: `${tenantId}/public/`,
      protected: `${tenantId}/protected/`,
      private: `${tenantId}/private/`
    }
  });
}
async function federatedTokenRefresh () {
  const federatedInfo = await Cache.getItem('federatedInfo');
  const response = await API.post('Cognito', '/oauth2/token', { body: { ...federatedInfo }, headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  console.log('federatedTokenRefresh: ' + response);
  return response;
}

export function configureAmplify (region) {
  region = region || config.region;
  const appClient = JSON.parse(localStorage.getItem('tenant-appClient')) || undefined;
  const oauthRegion = appClient ? JSON.parse(localStorage.getItem('tenant-region')) : region;
  const redirectUrl = config.stage === 'production' || config.stage === 'produs' ? 'https://my.buildsimple.com' : 'https://my.buildsimple-dev.com';
  Amplify.configure({
    Auth: {
      authenticationFlowType: 'USER_SRP_AUTH',
      identityPoolId: config[region].cognito.identitypool,
      region: config[region].cognito.region,
      userPoolId: config[region].cognito.userpool,
      userPoolWebClientId: appClient || config[region].cognito.webclient,
      oauth: {
        domain: config[oauthRegion].oauthDomain,
        scope: [
          'email',
          'openid',
          'aws.cognito.signin.user.admin'
        ],
        redirectSignIn: redirectUrl,
        redirectSignOut: redirectUrl,
        responseType: 'code'
      },
      refreshHandlers: {
        developer: federatedTokenRefresh
      }
    },
    Storage: {
      AWSS3: {
        bucket: config[region].storage.bucket,
        region: config[region].storage.region
      }
    },
    API: { endpoints: config[region].api },
    aws_cloud_logic_custom: config[region].api,
    aws_cognito_mfa_configuration: config.mfaConfiguration,
    aws_cognito_mfa_types: config.mfaTypes,
    aws_cognito_signup_attributes: config.signupAttributes,
    aws_cognito_verification_mechanisms: config.verificationMechanisms,
    aws_project_region: region
  });
}

export async function getIdentityId () {
  const user = await Auth.currentAuthenticatedUser({ bypassCache: false });
  const cachedId = localStorage.getItem(`${user.username}.identityId`) || undefined;
  if (cachedId) {
    return cachedId;
  } else {
    const credentials = await Auth.currentUserCredentials();
    localStorage.setItem(`${user.username}.identityId`, credentials.identityId);
    return credentials.identityId;
  }
}

export function getStage () {
  return config.stage;
}

export function getApiOAuth2Endpoint () {
  const tenantRegion = JSON.parse(localStorage.getItem('tenant-region')) || undefined;
  if (!tenantRegion) return undefined;
  return config[tenantRegion].apiOAuth2Endpoint;
}

export function getApiUrl () {
  const tenantRegion = JSON.parse(localStorage.getItem('tenant-region')) || undefined;
  if (!tenantRegion) return undefined;
  return config[tenantRegion].apiUrl;
}

export function getRegion () {
  return config.region;
}
