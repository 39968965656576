import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
  style = {},
  fill = '#444',
  height = '100%',
  className = '',
  viewBox = '0 0 24 24'
}) => (
  <svg width={height}
    style={style}
    height={height}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}><desc>Entity Extraction</desc>
    <g id="extract_rd" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M10.3943311,17.0011551 L3,17 C2.44771525,17 2,16.5522847 2,16 C2,15.4477153 2.44771525,15 3,15 L8.25524681,15.0007992 C8.81878197,15.8084697 9.54829205,16.491737 10.3943311,17.0011551 Z" id="Combined-Shape" fill={`${fill}`}></path>
      <path d="M7,11 C7,11.3395993 7.02418307,11.6735504 7.07092106,12.0002251 L3,12 C2.44771525,12 2,11.5522847 2,11 C2,10.4477153 2.44771525,10 3,10 L7.07092106,9.99977492 C7.02418307,10.3264496 7,10.6604007 7,11 Z" id="Combined-Shape" fill={`${fill}`}></path>
      <path d="M8.2549186,6.99967126 L3,7 C2.44771525,7 2,6.55228475 2,6 C2,5.44771525 2.44771525,5 3,5 L10.395336,4.99823992 C9.54870546,5.50782085 8.8187218,6.19147582 8.2549186,6.99967126 Z" id="Combined-Shape" fill={`${fill}`}></path>
      <path d="M16,6 C18.209139,6 20,7.790861 20,10 C20,12.209139 18.209139,14 16,14 C13.790861,14 12,12.209139 12,10 C12,7.790861 13.790861,6 16,6 Z M16,14 L16,20" id="Combined-Shape" stroke={`${fill}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" transform="translate(16.000000, 13.000000) rotate(-45.000000) translate(-16.000000, -13.000000) "></path>
    </g>
  </svg>
);

SVG.propTypes = {
  style: PropTypes.any,
  fill: PropTypes.any,
  height: PropTypes.any,
  className: PropTypes.any,
  viewBox: PropTypes.any
};

export default SVG;
