import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

export const withRouterInnerRef = (WrappedComponent) => {
  class InnerComponentWithRef extends React.Component {

    static propTypes = {
      forwardRef: PropTypes.any
    };

    render () {
      const { forwardRef, ...rest } = this.props;
      return <WrappedComponent {...rest} ref={forwardRef} />;
    }

  }

  const ComponentWithRef = withRouter(InnerComponentWithRef, { withRef: true });
  return React.forwardRef(function componentWithRef (props, ref) {
    return <ComponentWithRef {...props} forwardRef={ref} />;
  });
};

export function withRouter (Component) {
  function ComponentWithRouterProp (props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
        location={location}
        navigate={navigate}
        params={params}
      />
    );
  }

  return ComponentWithRouterProp;
}

export const withNavAndSideBar = ({ Component, sideBar, navBar, authState, prevAuthState }) => {
  if (!Component) return React.createElement('div');
  return React.createElement(Component, { sideBar, navBar, authState, prevAuthState });
};
