import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Popover from '@mui/material/Popover';
import withStyles from '@mui/styles/withStyles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import CompanyIcon from '@mui/icons-material/Business';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PaymentIcon from '@mui/icons-material/Payment';
import EmailIcon from '@mui/icons-material/Email';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import Button from '@mui/material/Button';
import { ensureCustomer, getDefaultPaymentMethod } from './data/Organization';
import NotificationsIcon from '@mui/icons-material/Notifications';
import clsx from 'clsx';
import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import './NavigationBar.css';
import Routes, { isAppVisibleForUser } from './routes';
import UserContext from './UserContext';
import { withRouterInnerRef } from './utils/withRouterRef';
import { NavLink } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import Badge from '@mui/material/Badge';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ProfileIcon from '@mui/icons-material/Storage';
import MenuItem from '@mui/material/MenuItem';
import { getProfileLabel } from './data/App';
import ModelIcon from '@mui/icons-material/BlurOn';
import DeveloperIcon from '@mui/icons-material/DeveloperMode';
import { isAdmin } from './data/groups';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import PersonIcon from '@mui/icons-material/Person';
import { ListItemButton } from '@mui/material';

const drawerWidth = 250;

const styles = theme => ({
  appBar: {
    backgroundColor: theme.palette.navbar.background,
    color: theme.palette.navbar.text,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  grow: {
    flexGrow: 1
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  avatar: {
    width: '32px',
    height: '32px'
  },
  avatarDetail: {
    width: '60px',
    height: '60px'
  }
});

class NavigationBar extends React.Component {

  static propTypes = {
    location: PropTypes.object,
    router: PropTypes.object,
    onMenuButtonPressed: PropTypes.func,
    classes: PropTypes.object,
    open: PropTypes.bool,
    shift: PropTypes.bool,
    setSelectedProfile: PropTypes.func,
    navigate: PropTypes.func
  };

  constructor (props) {
    super(props);

    this.state = {
      title: '',
      customContainer: undefined,
      hideShadow: false,
      showLoader: false,
      openUserMenuPopover: false,
      openNotificationPopover: false,
      userMenuAnchor: undefined,
      notificationAnchor: undefined,
      profileIndex: 0,
      profileName: '',
      profileChangeHandlers: [],
      showProfileSelection: false,
      showProfileSelectionDefault: false,
      isNewApiVersionAvailable: false,
      isAdmin: false,
      isDeveloper: false,
      isAIDesigner: false,
      isOperatorView: false,
      visibleApps: [],
      apps: [],
      appsFromExternalCustomer: [],
      loggingOut: false
    };

    this.menuButtonPressed = this.menuButtonPressed.bind(this);
    this.setTitle = this.setTitle.bind(this);
    this.showProfileSelection = this.showProfileSelection.bind(this);
    this.showCloseButton = this.showCloseButton.bind(this);
    this.setCustomContainer = this.setCustomContainer.bind(this);
    this.getCustomContainer = this.getCustomContainer.bind(this);
    this.hideShadow = this.hideShadow.bind(this);
    this.openUserMenuPopover = this.openUserMenuPopover.bind(this);
    this.closeUserMenuPopover = this.closeUserMenuPopover.bind(this);
    this.openNotificationPopover = this.openNotificationPopover.bind(this);
    this.closeNotificationPopover = this.closeNotificationPopover.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.updateNotificationState = this.updateNotificationState.bind(this);
    this.handleProfileChange = this.handleProfileChange.bind(this);
    this.addProfileChangeHandler = this.addProfileChangeHandler.bind(this);
    this.removeProfileChangeHandler = this.removeProfileChangeHandler.bind(this);
    this.informProfileChangeHandlers = this.informProfileChangeHandlers.bind(this);
    this.signOut = this.signOut.bind(this);
    this.isNotificationsLoading = false;
  }

  static contextType = UserContext;

  async componentDidMount () {
    this.updateTitle();
    let apps;
    let visibleApps;
    if (this.context && this.context.apps && this.context.apps.length > 0) {
      apps = this.context.apps;
      visibleApps = this.context.visibleApps;
    }
    this.setState({ apps, visibleApps }, () => {
      if (this.state.apps.length > 0) {
        let profileName = this.state.apps[0].profileName;
        if (this.context.user) {
          const tenant = this.context.user.signInUserSession.idToken.payload.logon.toUpperCase();
          const savedProfileName = localStorage.getItem(tenant + '.selectedProfile');
          if (savedProfileName) {
            if (this.state.apps.find(app => app.profileName === savedProfileName)) profileName = savedProfileName;
          }
        }
        this.handleProfileChange(null, profileName);
      }
      this.updateNotificationState();
    });
  }

  componentDidUpdate (prevProps, prevState) {
    const isOperatorPath = this.props.router?.location.pathname.includes('/operator/tenants');
    if (this.props.router?.location.pathname !== prevProps.router?.location.pathname && !prevProps.router?.location.pathname?.startsWith(this.props.router?.location.pathname)) {
      this.updateTitle();
      if (!isOperatorPath) {
        this.setState({
          isOperatorView: false,
          apps: this.context.apps,
          visibleApps: this.context.visibleApps,
          appsFromExternalCustomer: undefined
        });
      }
    }
    if (!this.isNotificationsLoading) this.updateNotificationState();
    if (this.state.isOperatorView && isOperatorPath && this.state.appsFromExternalCustomer && this.state.appsFromExternalCustomer !== prevState.appsFromExternalCustomer) {
      this.setState({ apps: this.state.appsFromExternalCustomer, visibleApps: this.state.appsFromExternalCustomer });
    }
    if (prevProps.shift !== this.props.shift) {
      this.setState({ shift: this.props.shift });
    }
  }

  setIsOperatorView = (isOperatorView) => {
    this.setState({ isOperatorView });
  };

  setAppsFromExternalCustomer = (apps) => {
    this.setState({ appsFromExternalCustomer: apps });
  };

  addProfileChangeHandler (handler) {
    if (!this.state.profileChangeHandlers.includes(handler)) {
      this.state.profileChangeHandlers.push(handler);
    }
  }

  removeProfileChangeHandler (handler) {
    if (this.state.profileChangeHandlers.includes(handler)) {
      this.state.profileChangeHandlers.splice(this.state.profileChangeHandlers.indexOf(handler), 1);
    }
  }

  informProfileChangeHandlers () {
    if (this.state.profileChangeHandlers) {
      this.state.profileChangeHandlers.forEach(changeHandler => {
        if (typeof changeHandler === 'function') { changeHandler(this.context.selectedProfile); }
      });
    }
  }

  handleProfileChange = (event, value) => {
    const newValue = value && value.props ? value.props.value : value;
    const appConfigs = this.state.apps;
    const newIndex = appConfigs.findIndex(config => config.profileName === newValue);
    this.props.setSelectedProfile({ name: newValue, index: newIndex });
    this.setState({
      profileName: newValue,
      profileIndex: newIndex
    }, this.informProfileChangeHandlers);
  };

  async updateNotificationState () {
    this.isNotificationsLoading = true;
    let numberOfNotifications = 0;

    const emailVerified = this.context.user.signInUserSession.idToken.payload.email_verified;
    if (emailVerified !== this.state.emailVerified) { this.setState({ emailVerified }); }
    if (!emailVerified) { numberOfNotifications++; }

    const isAIDesigner = isAppVisibleForUser('aisolutions', this.context.user.roles);
    if (this.state.isAIDesigner !== isAIDesigner) this.setState({ isAIDesigner });

    const isAdminUser = isAdmin(this.context.user);
    if (this.state.isAdmin !== isAdminUser) this.setState({ isAdmin: isAdminUser });

    const isDeveloper = isAppVisibleForUser('developers', this.context.user.roles);
    if (this.state.isDeveloper !== isDeveloper) this.setState({ isDeveloper });

    if (this.state.isNewApiVersionAvailable && (isAIDesigner || isAdminUser || isDeveloper)) numberOfNotifications++;

    const isNewAiModellAvailable = this.context.newAiModels && this.context.newAiModels && this.context.newAiModels.length > 0;
    if (isNewAiModellAvailable) numberOfNotifications = numberOfNotifications + this.context.newAiModels.length;
    if (isNewAiModellAvailable !== this.state.isNewAiModellAvailable) { this.setState({ isNewAiModellAvailable }); }

    if (numberOfNotifications !== this.state.numberOfNotifications) { this.setState({ numberOfNotifications }); }

    this.isNotificationsLoading = false;
  }

  updateTitle () {
    if (this.props && this.props.location && this.props.router?.location.pathname) {
      this.setCustomContainer(undefined);
      this.showCloseButton(false);
      this.showProfileSelection(this.state.showProfileSelectionDefault);
      const currentRoute = this.getCurrentRoute(Routes);
      if (currentRoute) {
        if ((currentRoute.text !== 'operator.tenants' && currentRoute.text !== 'customers.title') ||
          this.props.router?.location.pathname === '/operator/tenants' ||
          this.props.router?.location.pathname === '/administration/customers') {
          this.setTitle(<FormattedMessage id={currentRoute.text} />);
        }
      }
    }
  }

  getCurrentRoute (routes) {
    const currentRoutes = routes.filter(element => this.props.router?.location.pathname.startsWith(element.to));
    if (currentRoutes && currentRoutes.length > 0) {
      let currentRoute = currentRoutes[currentRoutes.length - 1];
      if (currentRoute.children) {
        const matchingChild = this.getCurrentRoute(currentRoute.children);
        if (matchingChild) { currentRoute = matchingChild; }
      }
      return currentRoute;
    }
    return undefined;
  }

  showProfileSelection (showProfileSelection) {
    this.setState({ showProfileSelection });
  }

  setLoading (load) {
    this.setState({ showLoader: load });
  }

  setCustomContainer (container) {
    this.setState({ customContainer: container });
  }

  getCustomContainer () {
    return this.state.customContainer;
  }

  hideShadow (hideShadow = false) {
    this.setState({ hideShadow });
  }

  setTitle (newTitle, skipSubtitle = false) {
    if (!skipSubtitle && this.props.location.pathname.startsWith('/operator/tenants/') && (this.props.location.pathname.match(/\//g) || []).length >= 4) {
      this.setState({ subTitle: newTitle });
    } else {
      this.setState({ title: newTitle, subTitle: undefined });
    }
  }

  showCloseButton (showCloseButton, callback) {
    const { title } = this.state;
    // console.log('set showCloseButton ', showCloseButton);
    if (showCloseButton && !this.state.showCloseButton) {
      this.setState({ previousTitle: title, showCloseButton, closeButtonCallback: callback });
    } else {
      this.setState({ showCloseButton });
    }
  }

  setPreviousTitle = () => {
    this.setState({ title: this.state.previousTitle });
  };

  showBackButton = (showBackButton, callback) => {
    this.setState({ showBackButton, backButtonCallback: callback });
  };

  async isPaymentMethodMissing () {
    const isOrganizationDataMissing = !this.context.user.organization;
    const userGroups = this.context.user.signInUserSession.idToken.payload['cognito:groups'];
    let paymentMethodMissing = false;
    if (isOrganizationDataMissing) {
      paymentMethodMissing = false;
    } else if (!userGroups.includes('Operator')) { // TODO Change to Admin
      paymentMethodMissing = false;
    } else {
      if (!isOrganizationDataMissing && !this.context.user.organization.customer) {
        const customerRes = await ensureCustomer(this.context.user.organization);
        this.context.user.organization = customerRes;
      }
      const customer = this.context.user.organization.customer;
      const paymentMethod = getDefaultPaymentMethod(customer);
      paymentMethodMissing = !paymentMethod;
    }
    return paymentMethodMissing;
  }

  menuButtonPressed (event) {
    if (this.state.showCloseButton && this.state.closeButtonCallback) {
      const result = this.state.closeButtonCallback();
      if (result === undefined || result) {
        this.setState({
          title: this.state.previousTitle,
          showCloseButton: false,
          closeButtonCallback: null
        });
      }
    } else if (this.state.showBackButton && this.state.backButtonCallback) {
      this.state.backButtonCallback();
      this.setState({
        previousTitle: undefined
        // showBackButton: false,
        // backButtonCallback: null
      });
    } else {
      this.props.onMenuButtonPressed();
    }
  }

  signOut = async (event) => {
    try {
      this.setState({ loggingOut: true });
      await this.context.signOut();
      this.props.navigate('/');
    } finally {
      this.setState({ loggingOut: false });
    }
  };

  openUserMenuPopover (event) {
    this.setState({ openUserMenuPopover: true });
    this.setState({ userMenuAnchor: event.currentTarget });
  }

  closeUserMenuPopover () {
    this.setState({ openUserMenuPopover: false });
  }

  openNotificationPopover (event) {
    this.setState({ openNotificationPopover: true, notificationAnchor: event.currentTarget });
  }

  closeNotificationPopover () {
    this.setState({ openNotificationPopover: false });
  }

  render () {
    const { classes } = this.props;

    const firstName = this.context.user.signInUserSession.idToken.payload.given_name || '';
    const lastName = this.context.user.signInUserSession.idToken.payload.family_name || '';
    const company = this.context.user.signInUserSession.idToken.payload.companyName || '';
    const email = this.context.user.signInUserSession.idToken.payload.email || '';
    const sso = !!this.context.user.attributes['custom:SAML2Groups'];
    const imagePreviewUrl = this.context.user.imagePreviewUrl;
    const avatar = <Avatar className={classes.avatar} src={imagePreviewUrl} alt='userProfile'><PersonIcon /></Avatar>;
    const hasAbo = this.context.apps.find(app => app.apps && app.apps.length > 0);

    return (
      <AppBar
        position="fixed"
        className={(this.state.hideShadow ? 'hideShadow ' : '') + (this.props.shift ? 'shift ' : 'noShift ') + clsx(classes.appBar, {
          [classes.appBarShift]: this.props.open && this.props.shift
        })}
        elevation={this.state.hideShadow ? 0 : 2}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            className={classes.menuButton}
            aria-label="open drawer"
            onClick={this.menuButtonPressed}
            size="large">
            {this.state.showCloseButton
              ? <CloseIcon />
              : this.state.showBackButton
                ? <ArrowBackIcon/>
                : <MenuIcon />
            }
          </IconButton>
          <Typography variant="h6" className={classes.title} noWrap>
            {this.state.title}
            {this.state.subTitle &&
              <span className='subTitle'>{this.state.subTitle}</span>
            }
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>

            <div className="customContainer">
              {this.state.customContainer}
            </div>

            {this.state.numberOfNotifications > 0 &&

              <IconButton onClick={this.openNotificationPopover} size="large">
                <Badge badgeContent={this.state.numberOfNotifications} color='primary'>
                  <NotificationsIcon />
                </Badge>
              </IconButton>

            }

            {this.state.showProfileSelection && this.state.visibleApps && this.state.visibleApps.length > 1 &&
              <TextField
                select
                id="profileName"
                name="profileName"
                value={this.state.profileName}
                onChange={this.handleProfileChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ProfileIcon />
                    </InputAdornment>
                  )
                }}
                SelectProps={{
                  native: false,
                  multiple: false
                }}
                size="small"
                variant="outlined"
                spellCheck="false"
                className="profile"
              >
                {this.state.visibleApps.filter(appConfig => {
                  const waitingForRegistration = hasAbo && (!appConfig.apps || appConfig.apps.length === 0 || !!appConfig.apps.find(app => (!app.serviceName || (app.status && app.status === 'PROVISIONING'))));
                  return !waitingForRegistration;
                }).map(appConfig => <MenuItem key={appConfig.profileName} value={appConfig.profileName}>{getProfileLabel(appConfig)}</MenuItem>)
                }
              </TextField>
            }

            <IconButton name='openUserProfileMenu' onClick={this.openUserMenuPopover} size="large">
              {avatar}
            </IconButton>

            <Popover
              id='notificationPopover'
              open={this.state.openNotificationPopover}
              onClose={this.closeNotificationPopover}
              anchorEl={this.state.notificationAnchor}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              className="notificationPopover"
            >
              {this.state.isOrganizationDataMissing &&
                <div>
                  <div className="caption">
                    <CompanyIcon />
                    <div>
                      <FormattedMessage id="organization.hint.requiredMissing.header"/>
                    </div>
                  </div>
                  <div className="description">
                    <FormattedHTMLMessage id="organization.hint.requiredMissing.description"/>
                  </div>
                  <Button variant='text' color='primary' component={NavLink} to="/organization" onClick={this.closeNotificationPopover}>
                    <FormattedMessage id="organization.hint.requiredMissing.link" />
                  </Button>
                </div>
              }
              {this.state.isPaymentMethodMissing &&
                <div>
                  <div className="caption">
                    <PaymentIcon />
                    <div>
                      <FormattedMessage id="organization.hint.paymentMissing.header"/>
                    </div>
                  </div>
                  <div className="description">
                    <FormattedHTMLMessage id="organization.hint.paymentMissing.description"/>
                  </div>
                  <Button variant='text' color='primary' component={NavLink} to="/organization" onClick={this.closeNotificationPopover}>
                    <FormattedMessage id="organization.hint.paymentMissing.link" />
                  </Button>
                </div>
              }
              {!this.state.emailVerified &&
                <div>
                  <div className="caption">
                    <EmailIcon />
                    <div>
                      <FormattedMessage id="account.general.verificationCodeMessageShort"/>
                    </div>
                  </div>
                  <div className="description">
                    <FormattedHTMLMessage id="account.general.verificationCodeMessage"/>
                  </div>
                  <Button variant='text' color='primary' component={NavLink} to="/account/general?sendVerification=true" onClick={this.closeNotificationPopover}>
                    <FormattedMessage id="account.general.verificationCodeResend" />
                  </Button>
                </div>
              }
              {this.state.isNewApiVersionAvailable &&
                <>
                {(this.state.isDeveloper || this.state.isAdmin) &&
                  <div>
                    <div className="caption">
                      <DeveloperIcon />
                      <div>
                        <FormattedMessage id='announcement.newApiVersion.header' values={{ version: 'v1.1' }}/>
                      </div>
                    </div>
                    <div className="description">
                      <div><FormattedHTMLMessage id='announcement.newApiVersion.developer.v1.1.description' /></div>
                      <div><FormattedHTMLMessage id='announcement.newApiVersion.developer.v1.1.description2' /></div>
                    </div>
                    <Button variant='text' color='primary' component={NavLink} to="/dev/apiDoc" onClick={this.closeNotificationPopover}>
                      <FormattedMessage id="announcement.newApiVersion.developer.action" />
                    </Button>
                    {this.state.isAIDesigner &&
                    <>
                      <div className="description">
                        <div><FormattedHTMLMessage id='announcement.newApiVersion.aiDesigner.v1.1.description2' /></div>
                      </div>
                      <Button variant='text' color='primary' component={NavLink} to="/aisolutions/eea" onClick={this.closeNotificationPopover}>
                        <FormattedMessage id="announcement.newApiVersion.aiDesigner.action" />
                      </Button>
                    </>
                    }
                  </div>
                }
                {this.state.isAIDesigner && !this.state.isAdmin && !this.state.isDeveloper &&
                  <div>
                    <div className="caption">
                      <DeveloperIcon />
                      <div>
                        <FormattedMessage id='announcement.newApiVersion.header' values={{ version: 'v1.1' }}/>
                      </div>
                    </div>
                    <div className="description">
                      <div><FormattedHTMLMessage id='announcement.newApiVersion.aiDesigner.v1.1.description' /></div>
                      <div><FormattedHTMLMessage id='announcement.newApiVersion.aiDesigner.v1.1.description2' /></div>
                    </div>
                    <Button variant='text' color='primary' component={NavLink} to="/aisolutions/eea" onClick={this.closeNotificationPopover}>
                      <FormattedMessage id="announcement.newApiVersion.aiDesigner.action" />
                    </Button>
                  </div>
                  }
                </>
              }
              {this.state.isAIDesigner && this.state.isNewAiModellAvailable &&
                <div>
                  <div className="caption">
                    <ModelIcon />
                    <div>
                      <FormattedMessage id={'models.solution.global.TRAINED' + (this.context.newAiModels.length > 1 ? '.multiple' : '')}/>
                    </div>
                  </div>
                  <div className="description">
                    <FormattedHTMLMessage id={'models.solution.global.TRAINED.description1' + (this.context.newAiModels.length > 1 ? '.multiple' : '')} />
                    <ul className='bold'>
                      {
                        this.context.newAiModels.map((nextModel, idx) => (<li key={nextModel.id || idx}>{nextModel.displayName}, Version {nextModel.mlModelVersion}.{nextModel.modelVersion}</li>))
                      }
                    </ul>
                    <FormattedHTMLMessage id={'models.solution.global.TRAINED.description2' + (this.context.newAiModels.length > 1 ? '.multiple' : '')} />
                  </div>
                  <Button variant='text' color='primary' component={NavLink} to="/aisolutions" onClick={this.closeNotificationPopover}>
                    <FormattedMessage id="models.solution.global.action" />
                  </Button>
                </div>
              }
            </Popover>

            <Popover
              id='userMenuPopover'
              open={this.state.openUserMenuPopover}
              onClose={this.closeUserMenuPopover}
              anchorEl={this.state.userMenuAnchor}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <div className="userMenuPopover">
                <div className="header">
                  <Avatar alt='userProfileImage' className={classes.avatar} src={imagePreviewUrl}>
                    <PersonIcon />
                  </Avatar>
                  <span>
                    <Typography className='company'>{company}</Typography>
                    <Typography className='username'>{firstName} {lastName}</Typography>
                    <Typography className='email'>{email}</Typography>
                  </span>
                  {sso &&
                    <div className="ssoContainer"><div className="sso">SSO</div></div>
                  }
                </div>
                <div className='buttonBar'>
                  <List component="nav" aria-label="main mailbox folders">
                    <ListItem>
                      <ListItemButton
                        component={NavLink} to="/account"
                        onClick={this.closeUserMenuPopover}
                      >
                        <ListItemIcon>
                          <SettingsRoundedIcon />
                        </ListItemIcon>
                        <ListItemText>
                          <FormattedMessage id='account.title' />
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                    <ListItem>
                      <ListItemButton
                        component={NavLink} to="/productUpdates"
                        onClick={this.closeUserMenuPopover}
                      >
                        <ListItemIcon>
                          <BrowserUpdatedIcon />
                        </ListItemIcon>
                        <ListItemText>
                          <FormattedMessage id='productUpdates.title' />
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                    <ListItem>
                      <ListItemButton
                        button
                        onClick={this.signOut}
                      >
                        <ListItemIcon>
                          <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText>
                          <FormattedMessage id='signin.logout' />
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </List>
                </div>
              </div>
            </Popover>
          </div>
        </Toolbar>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loggingOut}
          className="logoutBackdrop"
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {
          this.state.showLoader && <LinearProgress style={{ height: '3px' }} className={this.state.hideShadow ? 'hideShadow' : ''}/>
        }
      </AppBar>
    );
  }

}

export default withRouterInnerRef(withStyles(styles)(NavigationBar));
