import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
  style = {},
  fill = '#697B8F',
  height = '100%',
  className = '',
  viewBox = '0 0 24 24'
}) => (
  <svg width={height}
    style={style}
    height={height}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}
  >
    <desc>Document Verifier</desc>
    <g id="verify_rd" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(2.000000, 5.000000)">
        <g id="Group-2">
          <polyline id="Path-3" stroke={`${fill}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" points="10 10.0916667 13.15 13.425 19 3.425"></polyline>
          <rect id="path-7" fill={`${fill}`} fillRule="nonzero" x="0" y="0" width="9.9" height="2" rx="1"></rect>
          <rect id="path-7" fill={`${fill}`} fillRule="nonzero" x="0" y="5" width="11.55" height="2" rx="1"></rect>
          <rect id="path-7" fill={`${fill}`} fillRule="nonzero" x="1.81632487e-13" y="10" width="6.6" height="2" rx="1"></rect>
        </g>
      </g>
    </g>
  </svg>
);

SVG.propTypes = {
  style: PropTypes.any,
  fill: PropTypes.any,
  height: PropTypes.any,
  className: PropTypes.any,
  viewBox: PropTypes.any
};

export default SVG;
