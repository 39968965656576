import { createTheme } from '@mui/material/styles';

const theme = createTheme({

  palette: {

    // Blues
    primary: {
      light: '#1E99ED', // Light blue (Highlight)
      main: '#2196f3', // Primary color blue  (Footer Head)
      dark: '#353C48', // Dark blue (Headlines)
      contrastText: '#FDFDFD', // Contrast text white-ish
      hover: '#0988DE'

    },

    // Greens
    secondary: {
      main: '#2196f3', // Primary green
      contrastText: '#F9FAFB', // Contrast text white-ish
      highlight: '#2E6DA4', // Color for Links
      highlightSecondary: '#CBDFF',
      hover: '#75D1A8'
    },
    green: {
      main: '#54C793'
    },
    navbar: {
      background: '#fff',
      text: '#3a3a3a'
    },
    /// Text colour
    text: {
      main: '#697B8F',
      light: '#607B8F',
      headline: '#353C48',
      bread: '#697B8F'
    },

    // White/gray-ish
    background: {
      default: '#FFF',
      light: '#FFF',
      main: '#FFF',
      dark: '#EBF1F5',
      contrast: '#d4d9df'
    },

    // Red
    error: {
      main: '#DE4747'
    }

  },

  typography: {
    fontFamily: [
      '"Roboto"',
      'sans-serif'
    ].join(',')
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em'
        }
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      }
    }
  }
});
export default theme;
