import { API, Storage } from 'aws-amplify';
import { getRequestHeaders } from './Base';
import { FormattedMessage } from 'react-intl';
import { getGroupIcon } from './groups';
import React from 'react';

const profilePictureName = 'profile-picture';

export async function loadAllUsers (event) {
  const headers = await getRequestHeaders();
  return await API.get('CoreAPI', '/users', headers);
}

export async function addGroups (user, groups) {
  let needsUpdate = false;
  if (!user || !groups) { return; }
  if (!user.groups) { user.groups = groups; } else {
    groups.forEach(group => {
      if (!user.groups.includes(group)) { user.groups.push(group); needsUpdate = true; }
    });
  }
  return needsUpdate;
}

export async function saveUser (user, apps) {
  const request = await getRequestHeaders();
  request.body = user;
  if (apps) {
    request.queryStringParameters = {
      profiles: apps.map(app => app.profileName).join('|')
    };
  }
  const path = '/users' + (user.attributes ? `/${user.attributes.sub}` : '');
  return await API.post('CoreAPI', path, request);
}

export async function loadUser (userId, apps) {
  const request = await getRequestHeaders();

  if (apps) {
    request.queryStringParameters = {
      profiles: apps.map(app => app?.profileName).join('|')
    };
  }

  return await API.get('CoreAPI', `/users/${encodeURIComponent(userId)}`, request);
}

export async function getProfilePictureUrl (tenantId, userId, identityId, context) {
  let profileUrl;
  if (context) {
    profileUrl = await context.userImageHolder?.get(tenantId, userId) || await context.userImageHolder?.get(tenantId, identityId);
    if (profileUrl) { return profileUrl; }
  }
  Storage.configure({
    customPrefix: {
      public: `${tenantId}/public/`,
      protected: `${tenantId}/protected/`,
      private: `${tenantId}/private/`
    }
  });
  const url = await getImageUrl(tenantId, userId);
  let result;
  try {
    result = await Storage.list(url.folder, { level: 'public' });
  } catch (error) {
    console.error(url.folder + ' could not be loaded', error);
  }
  if (result && result.length > 0) {
    profileUrl = url.file + '?' + (result[0].lastModified.getTime());
  } else if (identityId) {
    profileUrl = await Storage.get(profilePictureName, { level: 'protected', identityId, expires: 360 });
    // if (!imageExists(profileUrl)) { profileUrl = undefined; }
    context?.userImageHolder?.set(tenantId, identityId, profileUrl);
  }
  if (userId) { context?.userImageHolder?.set(tenantId, userId, profileUrl); }

  return profileUrl;
}

async function getImageUrl (tenantId, userId) {
  Storage.configure({
    customPrefix: {
      public: `${tenantId}/public/`,
      protected: `${tenantId}/protected/`,
      private: `${tenantId}/private/`
    }
  });
  const region = `${Storage._config.AWSS3.region}`;
  const s3Url = {
    baseUrl: `https://${Storage._config.AWSS3.bucket}.s3.${region}.amazonaws.com/${tenantId}/public/`,
    folderName: `${profilePictureName}`,
    userId: `${userId}`
  };
  s3Url.folder = s3Url.folderName + '/' + s3Url.userId;
  s3Url.file = s3Url.baseUrl + s3Url.folder;
  return s3Url;
}

export async function loadSettings (profile) {
  const request = await getRequestHeaders();

  try {
    const settings = {};
    const response = await API.get('CoreAPI', `/settings/${profile.profileName}`, request);
    response.settings.forEach(obj => {
      const name = obj.name.split('/').slice(-1)[0];
      const value = obj.value;
      settings[name] = value;
    });
    if (!settings.DV) settings.DV = undefined;
    return settings;
  } catch (error) {
    console.error(error);
  }
}

export async function loadIdPData () {
  const request = await getRequestHeaders();
  return await API.get('CoreAPI', '/idp', request);
}

export const columns = [
  { id: 'profilePicture', disableSort: true, numeric: false, disablePadding: true, label: '', width: '60px' },
  { id: 'firstName', numeric: false, disablePadding: true, label: <FormattedMessage id="misc.firstname"/> },
  { id: 'lastName', numeric: false, disablePadding: false, label: <FormattedMessage id="misc.lastname"/> },
  { id: 'email', numeric: false, disablePadding: false, label: <FormattedMessage id="misc.email"/> },
  {
    id: 'TenantCostControlling',
    numeric: false,
    disablePadding: true,
    disableSort: true,
    width: '70px',
    className: 'globalPermissionCell',
    label: <div className='roleCaption'>{getGroupIcon('TenantCostControlling')}<span><FormattedMessage
        id="usermanagement.role.TenantCostControlling"/></span></div>
  },
  {
    id: 'TenantQualityControlling',
    numeric: false,
    disablePadding: true,
    disableSort: true,
    width: '70px',
    className: 'globalPermissionCell',
    label: <div className='roleCaption'>{getGroupIcon('TenantQualityControlling')}<span><FormattedMessage
        id="usermanagement.role.TenantQualityControlling"/></span></div>
  },
  {
    id: 'AIDesigner',
    numeric: false,
    disablePadding: true,
    disableSort: true,
    width: '70px',
    className: 'globalPermissionCell',
    label: <div className='roleCaption'>{getGroupIcon('AIDesigner')}<span><FormattedMessage
        id="usermanagement.role.AIDesigner"/></span></div>
  },
  {
    id: 'Development',
    numeric: false,
    disablePadding: true,
    disableSort: true,
    width: '70px',
    className: 'globalPermissionCell',
    label: <div className='roleCaption'>{getGroupIcon('Development')}<span><FormattedMessage
        id="usermanagement.role.Development"/></span></div>
  },
  {
    id: 'TenantAdmin',
    numeric: false,
    disablePadding: true,
    disableSort: true,
    width: '70px',
    className: 'globalPermissionCell',
    label: <div className='roleCaption'>{getGroupIcon('TenantAdmin')}<span><FormattedMessage
        id="usermanagement.role.TenantAdmin"/></span></div>
  },
  {
    id: 'Support',
    numeric: false,
    disablePadding: true,
    disableSort: true,
    width: '70px',
    className: 'globalPermissionCell',
    label: <div className='roleCaption'>{getGroupIcon('Support')}<span><FormattedMessage
        id="usermanagement.role.Support"/></span></div>
  },
  {
    id: 'Operator',
    numeric: false,
    disablePadding: true,
    disableSort: true,
    width: '70px',
    className: 'globalPermissionCell',
    label: <div className='roleCaption'>{getGroupIcon('Operator')}<span><FormattedMessage
        id="usermanagement.role.Operator"/></span></div>
  },
  {
    id: 'VerifierSplitting',
    numeric: false,
    disablePadding: true,
    disableSort: true,
    width: '80px',
    className: 'profilePermissionCell',
    label: <div className='roleCaption'>{getGroupIcon('VerifierSplitting')}<span><FormattedMessage
        id="usermanagement.role.VerifierSplitting"/></span></div>
  },
  {
    id: 'VerifierClassification',
    numeric: false,
    disablePadding: true,
    disableSort: true,
    width: '80px',
    className: 'profilePermissionCell',
    label: <div className='roleCaption'>{getGroupIcon('VerifierClassification')}<span><FormattedMessage
        id="usermanagement.role.VerifierClassification"/></span></div>
  },
  {
    id: 'VerifierEntityExtraction',
    numeric: false,
    disablePadding: true,
    disableSort: true,
    width: '80px',
    className: 'profilePermissionCell',
    label: <div className='roleCaption'>{getGroupIcon('VerifierEntityExtraction')}<span><FormattedMessage
        id="usermanagement.role.VerifierEntityExtraction"/></span></div>
  },

  {
    id: 'createdAt',
    numeric: true,
    disablePadding: true,
    label: <FormattedMessage id="misc.createdAt"/>,
    width: '115px'
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: <FormattedMessage id="misc.status"/>,
    width: '80px',
    align: 'center'
  }
];
