
import React from 'react';

import TenantUserIcon from '@mui/icons-material/Visibility';
import TenantAdminIcon from '@mui/icons-material/SettingsRounded';
import TenantCostControllingIcon from '@mui/icons-material/AttachMoney';
import TenantQualityControllingIcon from '@mui/icons-material/TimelineRounded';
import DvrRoundedIcon from '@mui/icons-material/DvrRounded';
import SupportAgent from '@mui/icons-material/SupportAgent';
import ModelIcon from '@mui/icons-material/BlurOn';
import CodeIcon from '@mui/icons-material/Code';
import { getService } from './services';

export function getGroupIcon (group) {
  if (group === 'TenantUser') {
    return <TenantUserIcon />;
  } else if (group === 'TenantAdmin') {
    return <TenantAdminIcon />;
  } else if (group === 'Operator') {
    return <DvrRoundedIcon />;
  } else if (group === 'Support') {
    return <SupportAgent />;
  } else if (group === 'TenantCostControlling') {
    return <TenantCostControllingIcon />;
  } else if (group === 'TenantQualityControlling') {
    return <TenantQualityControllingIcon />;
  } else if (group === 'AIDesigner') {
    return <ModelIcon />;
  } else if (group === 'VerifierSplitting') {
    const service = getService('SPA');
    return <service.icon className='verifierIcon small' />;
  } else if (group === 'VerifierEntityExtraction') {
    const service = getService('EEA');
    return <service.icon className='verifierIcon small' />;
  } else if (group === 'VerifierClassification') {
    const service = getService('DCA');
    return <service.icon className='verifierIcon small' />;
  } else if (group === 'Development') {
    return <CodeIcon />;
  }
}

export function isAdmin (user) {
  return isMemberOfGroup(user, 'TenantAdmin');
}
export function isCostController (user) {
  return isMemberOfGroup(user, 'TenantCostControlling');
}
export function isQualityController (user) {
  return isMemberOfGroup(user, 'TenantQualityControlling');
}
export function isUser (user) {
  return isMemberOfGroup(user, 'TenantUser');
}
export function isVerifier (user) {
  return isMemberOfGroup(user, 'VerifierSplitting') || isMemberOfGroup(user, 'VerifierEntityExtraction') || isMemberOfGroup(user, 'VerifierClassification');
}
export function isAIDesginer (user) {
  return isMemberOfGroup(user, 'AIDesigner');
}
export function isDeveloper (user) {
  return isMemberOfGroup(user, 'Development');
}
function isMemberOfGroup (user, groupName) {
  return user && user.roles && Array.isArray(user.roles) && user.roles.includes(groupName);
}
