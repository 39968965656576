import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
  style = {},
  fill = '#444',
  height = '100%',
  className = '',
  viewBox = '0 0 24 24'
}) => (
  <svg
    width={height}
    style={style}
    height={height}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}
  >
    <desc>Splitting API</desc>
    <g id="split_rd" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Splitting_Icon-1" transform="translate(1.000000, 4.000000)" fill={`${fill}`} fillRule="nonzero">
        <g id="Group-121" transform="translate(8.500000, 0.000000)">
          <g id="Group-120">
            <g id="scissors">
              <g id="Group">
                <path d="M4.13953269,11.64218 C4.59973706,12.0901183 4.88681503,12.7177219 4.88681503,13.4120448 C4.88681503,14.7695324 3.79121921,15.8737834 2.44471465,15.8737834 C1.0982101,15.8737834 0.00264502951,14.7695634 0.00264502951,13.4120758 C0.00264502951,12.0545883 1.09824086,10.9503373 2.44474541,10.9503373 C2.71247776,10.9503373 2.96999906,10.9946413 3.21125035,11.0752503 L3.21125035,11.0752503 L4.96964136,8.14129767 C5.17688194,8.40386899 5.46190255,8.60071807 5.78992633,8.69648553 L5.88958876,8.72214795 L4.13953269,11.64218 Z M2.44471465,12.0444192 C1.6966634,12.0444192 1.08799906,12.6579781 1.08799906,13.4120448 C1.08799906,14.1661116 1.6966634,14.7796705 2.44471465,14.7796705 C3.19273514,14.7796705 3.80143025,14.1661116 3.80143025,13.4120448 C3.80143025,12.6579781 3.1927659,12.0444192 2.44471465,12.0444192 Z" id="Combined-Shape"></path>
                <path d="M9.84913641,0 C10.3622414,0.312453104 10.5268791,0.985042673 10.2169186,1.50224278 L10.2169186,1.50224278 L7.47048778,6.08556302 C7.23067404,5.83334973 6.86456974,5.58670082 6.54413398,5.51409493 L9.84913641,0 Z M6.24745358,6.0226654 L6.23670951,6.0217702 C6.26558685,6.023071 6.29388785,6.02585651 6.32161039,6.030033 L6.25020471,6.02707715 L6.24745358,6.0226654 Z" id="Combined-Shape"></path>
              </g>
              <path d="M10.0423159,10.9503373 C9.77461426,10.9503373 9.51706221,10.9946103 9.27581092,11.0752503 L7.20730556,7.62397357 C7.28484184,7.47332766 7.32891541,7.30240546 7.32891541,7.12100406 C7.32891541,6.91948143 7.27466156,6.73085624 7.18036318,6.568677 C6.99299665,6.24645779 6.64692839,6.02949544 6.25023545,6.02707715 L6.24356138,6.0159159 L2.63786332,0 C2.12478911,0.312453104 1.96012064,0.985042673 2.27008119,1.50224278 L5.30663658,6.568708 C5.2123382,6.73082523 5.15808435,6.91951244 5.15808435,7.12100406 C5.15808435,7.30240546 5.20215791,7.47335866 5.2796942,7.62397357 C5.45328193,7.96126048 5.79572098,8.19592583 6.19429002,8.21381484 C6.21059078,8.21452789 6.2269838,8.21508598 6.24346912,8.21508598 C6.25995439,8.21508598 6.27634746,8.21455894 6.29264822,8.21381484 L8.3474363,11.64218 C7.88723191,12.0901493 7.60018473,12.7177219 7.60018473,13.4120448 C7.60018473,14.7695014 8.69578052,15.8737834 10.0422851,15.8737834 C11.3887897,15.8737834 12.4843855,14.7695324 12.4843855,13.4120448 C12.4843855,12.0545573 11.3888204,10.9503373 10.0423159,10.9503373 Z M5.70083826,7.12097306 C5.70083826,6.81884413 5.94378113,6.5739166 6.24353064,6.5739166 C6.54324937,6.5739166 6.78622302,6.81881313 6.78622302,7.12097306 C6.78622302,7.42310199 6.54328015,7.66802952 6.24353064,7.66802952 C5.94378113,7.66802952 5.70083826,7.423133 5.70083826,7.12097306 Z M10.0423159,14.7796705 C9.2942646,14.7796705 8.68560024,14.1661116 8.68560024,13.4120448 C8.68560024,12.6579781 9.2942646,12.0444192 10.0423159,12.0444192 C10.7903671,12.0444192 11.3990315,12.6579781 11.3990315,13.4120448 C11.3990315,14.1661116 10.7903364,14.7796705 10.0423159,14.7796705 Z" id="Shape"></path>
            </g>
          </g>
        </g>
        <g id="Group-3">
          <g id="Group-389">
            <g id="Rectangle" transform="translate(0.264000, 0.000000)">
              <g id="path-5-link" transform="translate(0.000000, 0.270746)">
                <rect id="path-5" x="0.736" y="0.729254404" width="7" height="2" rx="0.891"></rect>
                <rect id="path-5" x="0.736" y="5.7292544" width="9" height="2" rx="0.891"></rect>
                <rect id="path-5" x="0.736" y="10.7292544" width="5" height="2" rx="0.891"></rect>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

SVG.propTypes = {
  style: PropTypes.any,
  fill: PropTypes.any,
  height: PropTypes.any,
  className: PropTypes.any,
  viewBox: PropTypes.any
};

export default SVG;
