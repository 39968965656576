import { getCompanyLogoUrl } from '../data/Organization';
import { getProfilePictureUrl } from '../data/User';

export async function imageExists (imageUrl) {
  const test = await new Promise(resolve => {
    const img = new window.Image();
    img.onload = () => resolve({ imageUrl, status: 'ok' });
    img.onerror = () => resolve({ imageUrl, status: 'error' });

    img.src = imageUrl;
  });
  return test && test.status && test.status === 'ok';
}
export function needsRefresh (imageUrl) {
  const params = new URLSearchParams(imageUrl);
  if (params.has('X-Amz-Date')) {
    const awsDate = params.get('X-Amz-Date');
    const formattedDate = awsDate.substring(0, 4) + '-' + awsDate.substring(4, 6) + '-' + awsDate.substring(6, 11) + ':' + awsDate.substring(13);
    // console.log(formattedDate);
    const requestDate = new Date(formattedDate);

    const expiryInSeconds = params.has('X-Amz-Expires') ? params.get('X-Amz-Expires') : 900;

    const expiryDate = new Date(requestDate.getTime() + expiryInSeconds * 1000);
    if (expiryDate.getTime() < Date.now()) {
      // console.log(`url [${imageUrl}] needs refresh, expiryTime: ${expiryDate.getTime()} // currentTime: ${Date.now()}`);
      return true;
    } else {
      return false;
    }
  }
  return false;
}

export class CompanyImageHolder {

  images;
  constructor () {
    this.images = new Map();
  }

  set (tenantId, url) {
    this.images.set(tenantId, url);
  }

  remove (tenantId) {
    return this.images.delete(tenantId);
  }

  async get (tenantId) {
    let url = this.images.get(tenantId);
    if (!url) {
      url = await getCompanyLogoUrl(tenantId);
    }
    if (needsRefresh(url)) {
      const refreshedUrl = await getCompanyLogoUrl(tenantId);
      this.images.set(tenantId, refreshedUrl);
      return refreshedUrl;
    } else { return url; }
  }

  has (tenantId) {
    return this.images.has(tenantId);
  }

  async update () {
    const returnObj = {};
    for (const [key, value] of this.images) {
      if (needsRefresh(value)) {
        const refreshedUrl = await getCompanyLogoUrl(key);
        this.images.set(key, refreshedUrl);
      }
      returnObj[key] = value;
    }
    return returnObj;
  }

  reset () {
    this.images = new Map();
  }

}

export class UserImageHolder {

  images;
  constructor () {
    this.images = new Map();
  }

  set (tenantId, identityId, url) {
    this.images.set(identityId, { url, tenantId, identityId });
  }

  remove (identityId) {
    return this.images.delete(identityId);
  }

  async get (tenantId, identityId) {
    const value = this.images.get(identityId);
    if (!value) return undefined;
    const url = value.url;
    if (needsRefresh(url)) {
      const refreshedUrl = await getProfilePictureUrl(tenantId, identityId);
      this.images.set(identityId, { url: refreshedUrl, tenantId, identityId });
      return refreshedUrl;
    } else { return url; }
  }

  has (tenantId, identityId) {
    return this.images.has(identityId);
  }

  async update () {
    const returnObj = {};
    for (const [key, value] of this.images) {
      if (needsRefresh(value.url)) {
        const refreshedUrl = await getProfilePictureUrl(value.tenantId, value.identityId);
        this.images.set(key, { url: refreshedUrl, tenantId: value.tenantId, identityId: value.identityId });
      }
      returnObj[key] = value.url;
    }
    console.log('returnObj: ' + JSON.stringify(returnObj));
    return returnObj;
  }

  reset () {
    this.images = new Map();
  }

}
