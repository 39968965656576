import React, { Suspense } from 'react';
import { IntlProvider } from 'react-intl';
import Amplify, { Auth, Storage } from 'aws-amplify';
// React packages
import { Authenticator, SignIn, SignUp, Greetings, ForgotPassword, RequireNewPassword, VerifyContact } from 'aws-amplify-react';
// Module to get the browser language
import locale from './utils/locale';
// Context holding current authenticated user
import UserContext from './UserContext';
import App from './App';
import messagesDE from './translations/de.json';
import messagesEN from './translations/en.json';
import { loadOrganization, ensureCustomer, getProducts, getCustomer as getOrganizationCustomer } from './data/Organization';
import { CompanyImageHolder, imageExists, UserImageHolder } from './utils/ImageUtils';
import { loadApps } from './data/App';
import { getNewTrainedUndeployedModels } from './data/Modelbuilder';
import routes from './routes';
import { loadUser, getProfilePictureUrl } from './data/User';
import { configureAmplify, getIdentityId } from './data/Base';
import { LinearProgress } from '@mui/material';
import { getCustomer } from './data/CoreServicesCustomer';

// Own components
const BSSignIn = React.lazy(() => import('./components/auth/BSSignIn'));
const BSSignUp = React.lazy(() => import('./components/auth/BSSignUp'));
const BSForgotPw = React.lazy(() => import('./components/auth/BSForgotPw'));
const BSRequireNewPassword = React.lazy(() => import('./components/auth/BSRequireNewPw'));

const messages = {
  de: messagesDE,
  en: messagesEN
};

Amplify.Logger.LOG_LEVEL = 'INFO';

// configureAmplify();

class AppWithAuth extends React.Component {

  companyImageHolder = new CompanyImageHolder();
  userImageHolder = new UserImageHolder();

  constructor (props) {
    super(props);

    const language = this.configureLocale();
    this.state = {
      currentUser: {},
      apps: [],
      products: null,
      newAiModels: null,
      selectedProfile: null,
      hasVerifier: false,
      hasVerifierSubscription: false,
      isLoaded: false,
      locale: language,
      language: language.split(/[-_]/)[0], // language without region code
      ssoError: undefined
    };
    this.determineVerifierStatus = this.determineVerifierStatus.bind(this);
    this.loadProducts = this.loadProducts.bind(this);
    this.setSelectedProfile = this.setSelectedProfile.bind(this);
  }

  async componentDidMount () {
    const tenantRegion = JSON.parse(localStorage.getItem('tenant-region')) || undefined;
    if (tenantRegion) {
      configureAmplify(tenantRegion);
    } else {
      Auth.signOut();
    }

    try {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams && urlParams.get('error_description')) {
        this.setState({ ssoError: urlParams.get('error_description') });
      }
      const user = await Auth.currentAuthenticatedUser({ bypassCache: false });
      // console.log(user);
      const attributes = { tenant: user.signInUserSession.idToken.payload.logon, profile: '' };
      Auth.configure({ clientMetadata: attributes });
    } catch (error) {
      console.error(error);
    }
  }

  configureLocale () {
    return locale.startsWith('en') || locale.startsWith('de') ? locale : 'en-US';
  }

  onStateChange = async (authState) => {
    if (authState === 'loading') {
      this.setState({ loading: true });
    } else if (authState === 'signedIn') {
      const user = await Auth.currentAuthenticatedUser();
      user.receivedGreetings = false;
      user.groups = user.signInUserSession.idToken.payload['cognito:groups'];
      user.roles = user.signInUserSession.idToken.payload['cognito:groups'];
      try {
        user.organization = await loadOrganization(this);
      } catch (error) {
        console.error('Could not load organizations');
        console.error(error);
        user.organization = {};
      }

      this.loadCustomer();
      this.loadProfileImage(user);
      await this.loadApps();
      this.loadProducts(user);

      const userWithAllRoles = await loadUser(user.attributes.sub, this.state.apps);
      if (userWithAllRoles) {
        user.allRoles = userWithAllRoles.roles;
        this.updateVisibleApps(user);
        this.determineVerifierStatus(user.allRoles);
      }
      this.loadNewAiModels();
      this.setState({ currentUser: user, isLoaded: true });
    } else {
      this.setState({ currentUser: {}, isLoaded: false, newAiModels: undefined });
    }
  };

  updateVisibleApps (user) {
    const visibleApps = [];
    if (this.state.apps) {
      this.state.apps.forEach(app => {
        if (user.allRoles.includes('TenantAdmin') ||
            user.allRoles.includes('Operator') ||
            user.allRoles.includes('Support') ||
            user.allRoles.includes('TenantQualityControlling') ||
            user.allRoles.includes('AIDesigner') ||
            user.allRoles.includes('Development') ||
            user.allRoles.includes('Support') ||
            user.allRoles.includes(app.profileName + ':VerifierSplitting') ||
            user.allRoles.includes(app.profileName + ':VerifierEntityExtraction') ||
            user.allRoles.includes(app.profileName + ':VerifierClassification')
        ) {
          visibleApps.push(app);
        }
      });
    }
    const visibleAppsFiltered = visibleApps.filter(app => app.profileName !== 'COMMON' && app.profileName !== 'SHOP');
    this.setState({ visibleApps: visibleAppsFiltered });
  }

  loadProfileImage = async (user) => {
    const tenantId = user.signInUserSession.idToken.payload.tenantId;
    Storage.configure({
      customPrefix: {
        public: `${tenantId}/public/`,
        protected: `${tenantId}/protected/`,
        private: `${tenantId}/private/`
      }
    });
    const userId = user.attributes.sub;
    let url = await this.userImageHolder.get(tenantId, userId);
    if (!url) {
      const profileUrl = await getProfilePictureUrl(tenantId, userId, await getIdentityId());
      this.userImageHolder.set(tenantId, userId, profileUrl);
      url = profileUrl;
    }
    if (url) { user.imagePreviewUrl = url; }
  };

  setImagePreviewUrl = async (user, imagePreviewUrl) => {
    const exists = await imageExists(imagePreviewUrl);
    if (exists) { user.imagePreviewUrl = imagePreviewUrl; }
  };

  setUser = async (profile, reloadOrganization, bypassCache = false) => {
    // TODO UI error handling
    try {
      const user = await Auth.currentAuthenticatedUser({ bypassCache });
      user.receivedGreetings = true;
      user.groups = user.signInUserSession.idToken.payload['cognito:groups'];
      // user.groups.push('Operator');
      user.roles = user.signInUserSession.idToken.payload['cognito:groups'];
      try {
        user.organization = this.state.currentUser && this.state.currentUser.organization && !reloadOrganization ? this.state.currentUser.organization : await loadOrganization(this);
      } catch (error) {
        console.error('Could not load organizations');
        console.error(error);
        user.organization = {};
      }

      await this.loadProfileImage(user);
      const userWithAllRoles = await loadUser(user.attributes.sub, this.state.apps);
      if (userWithAllRoles) {
        user.allRoles = userWithAllRoles.roles;
        this.updateVisibleApps(user);
        this.determineVerifierStatus(user.allRoles);
      }
      if (profile) {
        this.setState({ currentUser: user, selectedProfile: profile });
      } else {
        this.setState({ currentUser: user });
      }
    } catch (error) {
      console.log(error);
    }
  };

  loadApps = async () => {
    const apps = await loadApps();
    this.setState({ apps });
    const user = this.state.currentUser;
    // Wenn der User mit den Rollen aller Profile vorliegt,
    // können wir die Liste der sichtbaren Apps aktualisieren
    if (user.allRoles) {
      this.updateVisibleApps(user);
    }
    return apps;
  };

  loadCustomer = async () => {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: false });
    try {
      const customer = await getCustomer(user.signInUserSession.idToken.payload.tenantId);
      this.setState({ customer });
    } catch (e) {
      console.error('Failed to customer: ' + e);
    }
  };

  loadProducts = async (user) => {
    if (!user) { user = this.state.currentUser; }
    await ensureCustomer(user.organization);
    const products = await getProducts(true, true, true, undefined);
    if (user.organization.customer && user.organization.customer.metadata && user.organization.customer.metadata.noCharges && user.organization.customer.metadata.noCharges === 'true') user.organization.customer = await getOrganizationCustomer(user.organization.customer.id);
    this.setState({ products, user }, this.determineVerifierStatus);
    return products;
  };

  async loadNewAiModels () {
    const currentSession = await Auth.currentSession();
    const userGroups = currentSession.idToken.payload['cognito:groups'];

    const aiSolutionRoute = routes.find(route => route.key === 'aisolutions');
    let isAiDesigner = false;
    aiSolutionRoute.roles.forEach(role => { if (userGroups.includes(role)) isAiDesigner = true; });
    if (isAiDesigner) {
      getNewTrainedUndeployedModels(this.state.apps).then((newAiModels) => this.setState({ newAiModels }));
    }
  }

  determineVerifierStatus (rolesToCheck) {
    const roles = rolesToCheck || this.state.user.roles;
    const verifierProduct = this.state.products && this.state.products.find(product => product.metadata && product.metadata.serviceId === 'DV');
    const basicPlatformProduct = this.state.products && this.state.products.find(product => product.metadata && product.metadata.serviceId === 'PLATFORM_BASIC');
    const premiumPlatformProduct = this.state.products && this.state.products.find(product => product.metadata && product.metadata.serviceId === 'PLATFORM_PREMIUM');
    const companyLicenseProduct = this.state.products && this.state.products.find(product => product.metadata && product.metadata.serviceId === 'COMPANY_LICENSE');

    const customer = this.state.user && this.state.user.organization ? this.state.user.organization.customer : null;
    const noCharges = customer && customer.metadata && customer.metadata.noCharges && customer.metadata.noCharges === 'true' && customer.metadata.noCharges_subscribedProducts &&
          ((verifierProduct && customer.metadata.noCharges_subscribedProducts.includes(verifierProduct.id)) ||
          (basicPlatformProduct && customer.metadata.noCharges_subscribedProducts.includes(basicPlatformProduct.id)) ||
          (premiumPlatformProduct && customer.metadata.noCharges_subscribedProducts.includes(premiumPlatformProduct.id)));
    const isVerifierUser = roles && roles.find(role => role.toLowerCase().includes('verifier')); // isAppVisibleForUser('verifier', roles);
    const hasVerifierSubscription = (verifierProduct && (!!verifierProduct.prices.find(price => price.subscription) || noCharges)) ||
      (basicPlatformProduct && (!!basicPlatformProduct.prices.find(price => price.subscription) || noCharges)) ||
      (premiumPlatformProduct && (!!premiumPlatformProduct.prices.find(price => price.subscription) || noCharges)) ||
      (companyLicenseProduct && (!!companyLicenseProduct.prices.find(price => price.subscription) || noCharges));
    const isVerifierInitialized = this.state.apps && this.state.apps.length > 0 && this.state.apps[0].apps?.find(app => (app.serviceName === 'DV' && app.status === 'SUBSCRIBED'));
    const hasVerifier = !!(hasVerifierSubscription || isVerifierInitialized);

    this.setState({ hasVerifier, hasVerifierSubscription, isVerifierUser, isVerifierInitialized });
  }

  setSelectedProfile (selectedProfile) {
    if (selectedProfile) {
      this.setState({ selectedProfile: selectedProfile });
      const tenant = this.state.currentUser.signInUserSession.idToken.payload.logon.toUpperCase();
      localStorage.setItem(tenant + '.selectedProfile', selectedProfile.name);
      this.setUser(selectedProfile);
    }
  }

  // TODO MS: This is not sustainable, we need a more permanent and flexible solution to reset data on logout
  signOut = async () => {
    await Auth.signOut();
    localStorage.removeItem('amplify-auth');
    localStorage.removeItem('amplify-storage');
    localStorage.removeItem('amplify-api');
    localStorage.removeItem('tenant-appClient');
    localStorage.removeItem('tenant-region');
    this.companyImageHolder.reset();
    this.userImageHolder.reset();
    const language = this.configureLocale();
    this.setState({
      currentUser: {},
      apps: [],
      products: null,
      newAiModels: null,
      hasVerifier: false,
      hasVerifierSubscription: false,
      isLoaded: false,
      locale: language,
      language: language.split(/[-_]/)[0] // language without region code
    });
  };

  render () {
    return (
      <UserContext.Provider value={{
        user: this.state.currentUser,
        setUser: this.setUser,
        apps: this.state.apps,
        visibleApps: this.state.visibleApps,
        products: this.state.products,
        loadApps: this.loadApps,
        isLoaded: this.state.isLoaded,
        determineVerifierStatus: this.determineVerifierStatus,
        loadProducts: this.loadProducts,
        selectedProfile: this.state.selectedProfile,
        hasVerifier: this.state.hasVerifier,
        hasVerifierSubscription: this.state.hasVerifierSubscription,
        isVerifierInitialized: this.state.isVerifierInitialized,
        newAiModels: this.state.newAiModels,
        loadNewAiModels: this.loadNewAiModels,
        companyImageHolder: this.companyImageHolder,
        userImageHolder: this.userImageHolder,
        signOut: this.signOut,
        customer: this.state.customer,
        isVerifierUser: this.state.isVerifierUser
      }}>
        <IntlProvider locale={this.state.locale}
          messages={messages[this.state.language]}
          defaultFormats={{
            date: {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric'
            }
          }}>
          <div>
            <Suspense fallback={<LinearProgress/>}>
              {/* We are going to use the Authenticator component right now because it offers more flexibility and customization */}
              <Authenticator hide={[SignIn, SignUp, Greetings, ForgotPassword, RequireNewPassword, VerifyContact]} onStateChange={this.onStateChange}>
                <BSSignIn error={this.state.ssoError}/>
                <BSSignUp />
                <App hasVerifier={this.state.hasVerifier} setSelectedProfile={this.setSelectedProfile} />
                <BSForgotPw />
                <BSRequireNewPassword />
              </Authenticator>
            </Suspense>
          </div>
        </IntlProvider>
      </UserContext.Provider>
    );
  }

}

export default AppWithAuth;
