import React from 'react';
import { API } from 'aws-amplify';
import { getRequestHeaders } from './Base';
import services, { cloneService } from './services';
import { FormattedMessage } from 'react-intl';
import pickBy from 'lodash/pickBy';

export async function loadApps (tenant) {
  const appConfs = [];
  const params = await getRequestHeaders();
  if (tenant) {
    params.queryStringParameters = {
      tenant
    };
  }
  const promiseResponse = await Promise.all([loadProfiles(tenant, false), API.get('CoreAPI', '/apps', params)]);
  const profiles = promiseResponse[0];
  const apps = promiseResponse[1] ? promiseResponse[1].appConfigs?.filter(app => app !== undefined) : undefined;

  profiles.forEach(profile => {
    const appConfig = apps.find(app => app.profileName === profile.profileName);
    if (appConfig) {
      appConfs.push(enrichAppConfig(appConfig, profile));
    }
  });
  appConfs.push(enrichAppConfig(apps.find(app => app.profileName === 'COMMON')));
  return appConfs;
}

export async function loadProfiles (tenant, shrinkByVerifierPermission) {
  const profiles = [];
  const params = await getRequestHeaders();
  if (tenant) {
    params.queryStringParameters = {
      tenant
    };
  }
  if (shrinkByVerifierPermission !== undefined) {
    if (!params.queryStringParameters) {
      params.queryStringParameters = {};
    }
    params.queryStringParameters.shrinkByVerifierPermission = shrinkByVerifierPermission;
  }
  try {
    const result = await API.get('CoreAPI', '/profiles', params);
    for (const profile of result.profiles) {
      profiles.push(profile);
    }
  } catch (error) {
    console.error('error: ', error);
    throw error;
  }
  if (profiles && profiles.length === 0) {
    const profileName = await createDefaultProfileConfig();
    profiles.push({ profileName: profileName });
  }
  return profiles;
}

export async function loadTrainingProfiles (tenant) {
  const profiles = await loadProfiles(tenant);
  return profiles.filter(profile => profile.params?.isTraining);
}

export async function getRoles (tenantId) {
  const additionalHeaders = {};
  if (tenantId) {
    additionalHeaders['tenant-id'] = tenantId;
  }
  const options = await getRequestHeaders(additionalHeaders);
  return await API.get('CoreAPI', '/roles', options);
}

const createDefaultProfileConfig = async () => {
  const profileName = 'COMMON';
  const displayName = 'Common';
  const params = await getRequestHeaders();
  params.body = {
    profileName: profileName,
    params: { displayName: displayName }
  };
  try {
    await API.post('CoreAPI', '/profiles', params);
  } catch (error) {
    console.error('createDefaultProfileConfig - error: ', error);
  }
  return profileName;
};

export const setDefaultTrainingProfile = async (profileName) => {
  const params = await getRequestHeaders();
  try {
    return await API.patch('CoreAPI', '/profiles/' + profileName + '/training', params);
  } catch (error) {
    console.error('setDefaultTrainingProfile - error: ', error);
  }
  return profileName;
};

function enrichAppConfig (appConfig, profile) {
  if (appConfig) {
    appConfig.services = services.map(service => {
      const clonedService = cloneService(service);
      clonedService.idx = services.indexOf(service);
      clonedService.connected = appConfig.apps && appConfig.apps.find(
        app => (clonedService.name === app.serviceName || clonedService.aka === app.serviceName)
      );
      if (clonedService.connected) {
        clonedService.apiBaseUrl = clonedService.connected.serviceUrl;
        clonedService.appBaseUrl = clonedService.connected.appUrl;
      }
      return clonedService;
    });
    appConfig.profile = profile;
  }
  return appConfig;
}

export function getProfileLabel (appConfig) {
  let label = '';
  if (appConfig && appConfig.profile) {
    if (appConfig.profile.params && Array.isArray(appConfig.profile.params) && appConfig.profile.params.length > 0 && appConfig.profile.params[0].displayName) {
      label = appConfig.profile.params[0].displayName;
    } else if (appConfig.profile.params && appConfig.profile.params.displayName) {
      label = appConfig.profile.params.displayName;
    } else {
      label = <FormattedMessage id={appConfig.profileName} />;
    }
  }
  return label;
}

export function getProfileFromApps (apps, profileName) {
  const app = apps.find(app => app.profileName === profileName);
  return app?.profile;
}

/**
 *
 * @param {'encrypt' | 'updateUrls' | 'updateSubscriptionStatus' | 'updateDvSettings' | 'copy' | 'deleteDeprecatedProfileSettings'} action
 * @returns response
 */
export async function patchApps (action) {
  const options = await getRequestHeaders();
  options.queryStringParameters = {
    action: action
  };
  return await API.patch('CoreAPI', '/apps', options);
};

export async function queryMeteringStats (queryParams) {
  const options = await getRequestHeaders();
  options.queryStringParameters = pickBy(queryParams); // remove null values from queryParams
  return await API.get('CoreManagementAPI', '/admin/metering-stats', options);
};
