import { API, Storage } from 'aws-amplify';
import { v4 } from 'uuid';
import { imageExists } from '../utils/ImageUtils';
import { getRequestHeaders, configureStorage } from './Base';

const companyLogoKey = 'company_logo';
export const regulatedIndustries = ['Banken', 'Banking & Investment Management', 'Versicherung', 'Insurance',
  'Energie', 'Energy', 'Gesundheit/Soziales/Pflege', 'Hospitals & Healthcare', 'Medien', 'Broadcast Media & Production', 'Transport/Verkehr/Logistik', 'Transportation & Logistics', 'Finanz', 'Financial Services & Accounting', 'Öffentliche Verwaltung', 'Government & Military'];

export async function loadOrganization (context) {
  const header = await getRequestHeaders();
  try {
    const organizations = await API.get('OrganizationAPI', '/organizations', header);
    let organization = { isRequiredDataMissing: true };
    if (Array.isArray(organizations) && organizations.length > 0) {
      organization = organizations[0];
      await ensureCustomer(organization);
      organization.orgId = organization.orgId.replace('org#', '');
      organization.industry = (organization.industries && Array.isArray(organization.industries) && organization.industries.length > 0) ? organization.industries[0] : null;
      organization.logoUrl = await getCompanyLogoUrl(organization.tenantId, context);
      organization.isRequiredDataMissing = isRequiredOrganizationDataMissing(organization);
    }
    return organization;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function ensureCustomer (organization) {
  if (organization && !organization.customer) {
    try {
      if (organization.customerId) organization.customer = await getCustomer(organization.customerId);
      if (!organization.customer) organization.customer = await findCustomer(organization);
      if (!organization.customer) {
        console.warn('Could not find customer');
      } else if (!organization.customerId) {
        saveOrganization(organization, organization.customer, false);
      }
    } catch (error) {
      console.warn('Could not find customer: ', error);
    }
  }
  return organization;
}

export function isRequiredOrganizationDataMissing (org) {
  if (!org) { return true; }

  if (!org.name || org.name === '') { return true; }
  if ((!org.vatId || org.vatId === '') && !org.exemptFromVat) { return true; }
  if (!org.industries || org.industries === '' || (Array.isArray(org.industries) && org.industries.length === 0)) { return true; }

  if (!org.address) { return true; }
  if (!org.billingAddress) { return true; }

  if (!org.contactName || org.contactName === '') { return true; }
  if (!org.contactEmailAddress || org.contactEmailAddress === '') { return true; }
  if (!org.billingEmailAddress || org.billingEmailAddress === '') { return true; }
  if (!org.contactPhoneNumber || org.contactPhoneNumber === '') { return true; }

  // Check if Stripe has registered a VatId
  if (org.customer && !org.exemptFromVat) {
    if (org.customer.tax_ids.total_count === 0) { return true; }
  }

  return false;
}

export function getDefaultPaymentMethod (customer) {
  let paymentMethod;
  if (customer && customer.defaultPaymentMethod) {
    paymentMethod = customer.defaultPaymentMethod;
  } else if (customer && customer.metadata?.isManualPaymentDefault === 'true' && customer.metadata?.allowManualPayment === 'true') {
    paymentMethod = { type: 'manual_payment' };
  } else if (customer && customer.paymentMethods && customer.paymentMethods.length > 0) {
    if (customer.invoice_settings && customer.invoice_settings.default_payment_method) {
      paymentMethod = customer.paymentMethods.find(paymentMethod => (paymentMethod.id === customer.invoice_settings.default_payment_method));
    }
  }
  return paymentMethod;
}

export async function saveOrganization (organization, customerReq, createCustomerIfNotPresent) {
  if (organization.customer) organization.customer = undefined;
  if (organization && organization.orgId) {
    if (!organization.customerId) organization.customerId = customerReq?.id; // FIXME check this
    await updateOrganization(organization);

    // filter customer by email
    let customer = customerReq || (await findCustomer(organization));
    if (customer) {
      // update
      customer = await updateCustomer(organization, customer);
    } else if (createCustomerIfNotPresent) {
      // create
      console.log('Could not filter customer by email[' + organization.contactEmailAddress + ']. Creating new customer...');
      customer = await createCustomer(organization);
    }

    // update organization with new customerId
    if (customer && customer.id !== organization.customerId) {
      organization.customerId = customer.id;
      await updateOrganization(organization);
    }
    // set reference to customer
    organization.customer = customer;
  } else {
    await createOrganization(organization);
    if (createCustomerIfNotPresent) {
      const customer = await createCustomer(organization);
      // set reference to customer
      organization.customer = customer;
    }
  }
}

async function createOrganization (organization) {
  const request = await getRequestHeaders();
  organization.orgId = generateValidOrganizationId();
  request.body = organization;

  const response = await API.post('OrganizationAPI', '/organizations', request);
  console.log('create organization response: ', response);
  return response;
}

async function createCustomer (organization) {
  const request = await getRequestHeaders();
  request.body = { organization: organization };

  const response = await API.post('CustomersAPI', '/customers', request);
  console.log('createCustomer response: ', response);
  return response.customer;
}

export async function updateCustomer (organization, customer) {
  const request = await getRequestHeaders();
  request.body = {
    organization: organization,
    customer: customer
  };
  const response = await API.put('CustomersAPI', '/customers/' + customer.id, request);
  console.log('updateCustomer response: ', response);
  return response.customer;
}

export async function setManualPaymentDefault (customer, manualPaymentDefault) {
  const request = await getRequestHeaders();
  request.body = {
    manualPaymentDefault
  };
  const response = await API.put('CustomersAPI', `/customers/${customer.id}/manual-payment`, request);
  console.log('updateCustomer response: ', response);
  return response.customer;
}

export async function updateOrganization (organization) {
  const request = await getRequestHeaders();
  checkValidOrganizationId(organization.orgId);
  request.body = organization;

  const response = await API.put('OrganizationAPI', '/organizations/' + organization.orgId, request);
  console.log('updateOrganization response: ', response);
  return response;
}

export async function createInvoiceItem (customerId, priceId, poNumber) {
  const request = await getRequestHeaders();
  request.body = { priceId, poNumber };
  const invoiceItem = await API.post('CustomersAPI', '/customers/' + customerId + '/invoiceitems', request);
  return (invoiceItem) || undefined;
}

export async function meterExtraServices (productId, customerId, price, quantity, serviceId) {
  const request = await getRequestHeaders();
  request.body = {
    productId,
    customerId,
    quantity,
    price,
    serviceId
  };
  const invoiceOrSubscription = await API.post('CustomersAPI', '/metering/extraservices', request);
  return invoiceOrSubscription;
}

export async function getSubscribedOptionalServices (customer) {
  const request = await getRequestHeaders();
  request.queryStringParameters = {
    customer: customer
  };

  const products = await API.get('CustomersAPI', '/metering/extraservices/products', request);
  return products;
}

export async function createSubscription (customerId, priceId, poNumber, manualPayment, subscriptionId, productId, appsPerProfile) {
  const manualPay = manualPayment || false;
  const request = await getRequestHeaders();
  request.body = { priceId, poNumber, manualPayment: manualPay, subscriptionId, productId };
  if (appsPerProfile && appsPerProfile.length > 0) {
    request.body.registeredApps = appsPerProfile[0]?.apps?.filter(app => app.status === 'SUBSCRIBED')?.map(app => app.serviceName) || [];
  }
  const subscription = await API.post('CustomersAPI', '/customers/' + customerId + '/subscriptions', request);
  return (subscription) || undefined;
}

export async function cancelSubscription (subscriptionId, subscriptionItemId, productId, customerId, noServiceDeregistration) {
  const request = await getRequestHeaders();
  request.body = { subscriptionItemId, productId, customerId, noServiceDeregistration };
  const subscription = await API.del('CustomersAPI', '/customers/subscriptions/' + subscriptionId, request);
  return (subscription) || undefined;
}

export async function updateSubscription (subscriptionId, subscriptionItemId, priceId, poNumber, productId) {
  const request = await getRequestHeaders();
  request.body = { priceId, poNumber, subscriptionItemId, productId };
  const subscription = await API.post('CustomersAPI', '/customers/subscriptions/' + subscriptionId, request);
  return (subscription) || undefined;
}

export async function updateSubscriptionRegistration (customerId, profile, appsPerProfile) {
  const request = await getRequestHeaders();
  if (profile) {
    request.body = { profile };
  }
  if (customerId) {
    request.body.customerId = customerId;
  }
  if (appsPerProfile && appsPerProfile.length > 0) {
    request.body.registeredApps = appsPerProfile[0]?.apps?.filter(app => app.status === 'SUBSCRIBED')?.map(app => app.serviceName) || [];
  }
  const subscription = await API.patch('CustomersAPI', '/customers/' + customerId + '/subscriptions', request);
  return (subscription) || undefined;
}

export function hasSubscriptions (products, customer) {
  if (!products || products.length === 0 || !customer) { return false; }

  const exemptFromCharges = customer && customer.metadata && customer.metadata.noCharges && customer.metadata.noCharges === 'true';

  let hasSubscriptions = false;
  products.forEach(product => {
    const subscribedPrice = product.prices && product.prices.find(price => price.subscription);
    const subscribedWithNoCharge = exemptFromCharges && customer.metadata.noCharges_subscribedProducts && customer.metadata.noCharges_subscribedProducts.includes(product.id);
    hasSubscriptions = hasSubscriptions || subscribedPrice || subscribedWithNoCharge;
  });
  return hasSubscriptions;
}

export async function testMetering (price, usageQuantity) {
  const request = await getRequestHeaders();
  const subscriptionItemId = getSubscriptionItemByPrice(price).id;
  request.body = { usageQuantity };
  const subscription = await API.post('CustomersAPI', '/customers/metering/' + subscriptionItemId, request);
  return (subscription) || undefined;
}

export function getSubscriptionItemByPrice (price) {
  if (!price || !price.subscription || !price.subscription.items || !price.subscription.items.data) { return null; } else { return price.subscription.items.data.find(subItem => subItem.price && subItem.price.id === price.id); }
}

export async function getProducts (includePrices, onlyActiveProducts, includeSubscriptions, customerId, type) {
  const request = await getRequestHeaders();
  request.queryStringParameters = {
    limit: 100,
    type: type || 'service',
    includePrices,
    includeSubscriptions,
    active: onlyActiveProducts
  };
  if (customerId) {
    request.queryStringParameters.customerId = customerId;
  }

  const promises = [];
  promises.push(API.get('CustomersAPI', '/products', request));
  if (includePrices && includeSubscriptions) {
    promises.push(getSubscriptions(customerId));
  } else promises.push([]);

  const promisesResults = await Promise.all(promises);
  const products = promisesResults[0];
  const subscriptions = promisesResults[1];

  if (products && includePrices) {
    products.forEach(product => {
      product.prices.forEach(price => {
        const sub = subscriptions.find(subscription => (subscription.items && subscription.items.data && subscription.items.data.find(subItem => subItem.price && subItem.price.id === price.id && !(subItem.metadata && subItem.metadata.deleted && subItem.metadata.deleted === 'true'))));
        if (sub) price.subscription = sub;
        const deletedSub = subscriptions.find(subscription => (subscription.items && subscription.items.data && subscription.items.data.find(subItem => subItem.price && subItem.price.id === price.id && (subItem.metadata && subItem.metadata.deleted && subItem.metadata.deleted === 'true'))));
        if (deletedSub) { price.deletedSubscription = deletedSub; }
      });
    });
  }
  return (products) || [];
}

export async function getPrices (productId) {
  const request = await getRequestHeaders();
  request.queryStringParameters = {
    limit: 100,
    product: productId
  };
  const prices = await API.get('CustomersAPI', '/prices', request);

  return (prices) || [];
}

/* params: { metadata, pricing, newPrice, product }; */
export async function addNewPrice (params, serviceName) {
  const request = await getRequestHeaders();
  request.body = params;
  const response = await API.post('CustomersAPI', `/prices/add/${serviceName}`, request);
  console.log('updateCustomer response: ', response);
  return response;
}

export function isPriceToShow (price, customer, product) {
  if (!price || !customer || !product) { return false; }

  if (price.subscription) return true;

  if (product.default_price === price.id) return false;

  if (price.metadata && price.metadata.hidePerDefault === 'true' && customer.metadata && (!customer?.metadata?.pricesToShow || !customer?.metadata?.pricesToShow.includes(price.id))) { return false; }

  if (customer.metadata && customer.metadata.pricesToHide && customer.metadata.pricesToHide.includes(price.id)) { return false; }

  if ((!price.metadata || price.metadata.hidePerDefault !== 'true') && customer.metadata && customer.metadata.productsToHideDefaultPricesFor && customer.metadata.productsToHideDefaultPricesFor.includes(price.product)) { return false; }

  return true;
}

export function isNewPriceToShow (price, customer, product) {
  if (!price || !customer || !product) { return false; }

  if (price.subscription) return true;

  if (customer.metadata && customer.metadata.pricesToHide && customer.metadata.pricesToHide.includes(price.id)) { return false; }

  if ((!price.metadata || price.metadata.hidePerDefault !== 'true') && customer.metadata && customer.metadata.productsToHideDefaultPricesFor && customer.metadata.productsToHideDefaultPricesFor.includes(price.product)) { return false; }

  if ((product.default_price === price.id || price?.metadata?.isDefault === 'true') && product.metadata?.serviceId !== 'COMPANY_LICENSE' && product.metadata?.serviceId !== 'PLATFORM_PREMIUM') return true;

  if (customer.metadata && customer.metadata.pricesToShow?.includes(price.id)) { return true; }

  return false;
}

export async function getPaymentMethod (customerId, paymentMethodId) {
  const request = await getRequestHeaders();
  const paymentMethod = await API.get('CustomersAPI', '/customers/' + customerId + '/paymentmethods/' + paymentMethodId, request);
  return paymentMethod && paymentMethod.paymentMethod;
}

async function getSubscriptions (customerId) {
  const request = await getRequestHeaders();
  if (customerId) {
    request.queryStringParameters = {
      customerId
    };
  }

  const subscriptions = await API.get('CustomersAPI', '/subscriptions', request);
  return (subscriptions) || [];
}

export async function getInvoiceItems (customerId) {
  const request = await getRequestHeaders();
  request.queryStringParameters = {
    customerId,
    limit: 90
  };
  const invoiceItems = await API.get('CustomersAPI', '/invoiceitems', request);
  return (invoiceItems) || [];
}

export async function getCustomer (customerId) {
  const request = await getRequestHeaders();
  try {
    const customersRes = await API.get('CustomersAPI', '/customers/' + customerId, request);
    return customersRes.customer;
  } catch (error) {
    console.log('error: ', error);
  }
}

async function findCustomer (organization) {
  const request = await getRequestHeaders();
  request.queryStringParameters = {
    email: organization.billingEmailAddress || organization.contactEmailAddress
  };
  try {
    const customersRes = await API.get('CustomersAPI', '/customers', request);
    if (customersRes.customers && customersRes.customers.length === 1) {
      const customer = customersRes.customers[0];
      console.log('Filtered customer by email[' + organization.contactEmailAddress + ']: ', customer);
      return customer;
    }
  } catch (error) {
    console.log('error: ', error);
  }
}

function checkValidOrganizationId (orgId) {
  if (!orgId) {
    throw new Error('Please provide valid orgId[' + orgId + ']');
  }
}

function generateValidOrganizationId (orgId) {
  let newOrgId = '';
  if (orgId) {
    checkValidOrganizationId(orgId);
    newOrgId = orgId;
  } else {
    newOrgId = v4();
  }
  return newOrgId;
}

export async function saveCompanyLogo (tenantId, file, fileType, context) {
  configureStorage(tenantId);
  try {
    await Storage.put(companyLogoKey, file,
      {
        contentType: fileType,
        level: 'public',
        acl: 'public-read',
        serverSideEncryption: 'AES256'
      });
    context?.companyImageHolder?.remove(tenantId);
    return getCompanyLogoUrl(tenantId, context);
  } catch (err) {
    throw new Error('Could not upload profile picture: ' + err, err);
  }
}

export async function getCompanyLogoUrl (tenantId, context) {
  let imageUrl;
  if (context?.companyImageHolder?.has(tenantId)) {
    return await context.companyImageHolder.get(tenantId);
  }
  configureStorage(tenantId);
  imageUrl = `https://${Storage._config.AWSS3.bucket}.s3.${Storage._config.AWSS3.region}.amazonaws.com/${tenantId}/public/${companyLogoKey}`;
  let result;
  try {
    result = await Storage.list(companyLogoKey, { level: 'public' });
  } catch (error) {
    console.debug(companyLogoKey + ' could not be loaded', error);
  }
  if (result && result.length > 0) {
    imageUrl = imageUrl + '?' + (result[0].lastModified.getTime());
  } else {
    imageUrl = await Storage.get(companyLogoKey, { expires: 3600 })
      .catch(err => console.log(err));
    const imageEx = await imageExists(imageUrl);
    if (!imageEx) { imageUrl = undefined; }
  }
  context?.companyImageHolder?.set(tenantId, imageUrl);
  return imageUrl;
}

export function cleanVatId (vatId) {
  if (!vatId) { return vatId; }

  // capitalize characters
  let cleanVatId = vatId.replace(/^(.)|\s*(.)/g, c => c.toUpperCase());
  // remove all disallowed chars
  if (!cleanVatId.includes('CHE')) {
    cleanVatId = cleanVatId.replace(/\s/g, '').replace(/[^A-Z0-9]/g, '');
  }

  // if vat expression is matching: clear other characters
  const vatRegex = /^((AT)U[0-9]{8}|(BE)0[0-9]{9}|(BG)[0-9]{9,10}|(CHE)[- ]?[0-9]{3}.[0-9]{3}.[0-9]{3} (MWST|TVA|IVA)|(CY)[0-9]{8}L|(CZ)[0-9]{8,10}|(DE)[0-9]{9}|(DK)[0-9]{8}|(EE)[0-9]{9}|(EL|GR)[0-9]{9}|(ES)[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)[0-9]{8}|(FR)[0-9A-Z]{2}[0-9]{9}|(GB)([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)[0-9]{8}|(IE)[0-9]S[0-9]{5}L|(IT)[0-9]{11}|(LT)([0-9]{9}|[0-9]{12})|(LU)[0-9]{8}|(LV)[0-9]{11}|(MT)[0-9]{8}|(NL)[0-9]{9}B[0-9]{2}|(PL)[0-9]{10}|(PT)[0-9]{9}|(RO)[0-9]{2,10}|(SE)[0-9]{12}|(SI)[0-9]{8}|(SK)[0-9]{10})/i;
  const foundVat = cleanVatId.match(vatRegex);
  if (foundVat && foundVat.length > 0) {
    cleanVatId = foundVat[0];
  }

  return cleanVatId;
}

export async function validateVatId (vatId, countryCode) {
  const euCountries = ['AT', 'BE', 'BG', 'CHE', 'CH', 'CY', 'CZ', 'DE', 'DK', 'EE', 'EL', 'GR', 'ES', 'FI', 'FR', 'HU', 'IE', 'IT', 'LT', 'LU', 'LV', 'MT', 'NL', 'PL', 'PL', 'RO', 'SE', 'SI', 'SK'];
  const isEuCountry = euCountries.includes(countryCode);
  const request = { headers: {} };
  request.body = { vat: vatId };

  /**
     * https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s21.html
     */
  const regex = /^((AT)U[0-9]{8}|(BE)0[0-9]{9}|(BG)[0-9]{9,10}|(CHE)[- ]?[0-9]{3}.[0-9]{3}.[0-9]{3} (MWST|TVA|IVA)|(CY)[0-9]{8}L|(CZ)[0-9]{8,10}|(DE)[0-9]{9}|(DK)[0-9]{8}|(EE)[0-9]{9}|(EL|GR)[0-9]{9}|(ES)[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)[0-9]{8}|(FR)[0-9A-Z]{2}[0-9]{9}|(GB)([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)[0-9]{8}|(IE)[0-9]S[0-9]{5}L|(IT)[0-9]{11}|(LT)([0-9]{9}|[0-9]{12})|(LU)[0-9]{8}|(LV)[0-9]{11}|(MT)[0-9]{8}|(NL)[0-9]{9}B[0-9]{2}|(PL)[0-9]{10}|(PT)[0-9]{9}|(RO)[0-9]{2,10}|(SE)[0-9]{12}|(SI)[0-9]{8}|(SK)[0-9]{10})$/i;

  if (!vatId || (isEuCountry && !vatId.match(regex))) {
    const vatIdValidation = { valid: false, statusCode: 403 };
    return vatIdValidation;
  }

  if (isEuCountry) { return { statusCode: 200, valid: true }; } else { return { statusCode: 501, valid: true }; }

  // Do not use online validation service
  /**
   try {
    const vatValidationResponse = await API.post('CoreTenantManagementAPI', '/registrations/validations', request);
    return vatValidationResponse.vatIdValidation;
  } catch (error) {
    return error.response && error.response.data ? error.response.data.vatIdValidation : { statusCode: 499 };
  }
  */
}

export function hasFeatureFlag (customer, feature) {
  return customer?.activeFeatures?.includes(feature);
}

export function getFeaturesToShow (context) {
  const features = (context?.user?.organization?.customer?.metadata?.featuresToShow?.split(',').map(feature => feature.trim())) ?? [];
  features.arePipelinesVisible = true;
  return features;
}

export function getFeaturesToHide (context) {
  return context && context.user && context.user.organization && context.user.organization.customer && context.user.organization.customer.metadata && context.user.organization.customer.metadata.featuresToHide ? context.user.organization.customer.metadata.featuresToHide.split(',').map(feature => feature.trim()) : [];
}

export function getSubscriptionForService (context, serviceName) {
  const { apps } = context;

  const subscriptionForService = apps.find(app => app?.apps?.find(app => app?.serviceName === serviceName && (app?.status === 'SUBSCRIBED' || (!app?.status && app?.customerId)))) || apps.find(app => app?.apps?.find(app => app?.serviceName === 'COMPANY_LICENSE' && (app?.status === 'SUBSCRIBED' || (!app?.status && app?.customerId))));
  return subscriptionForService;
}

export function getSubscribedPlatformProduct (products, customer) {
  const noChargesSubscribedProducts = customer?.metadata?.noCharges === 'true' ? customer?.metadata?.noCharges_subscribedProducts : undefined;
  const subscribedProduct = products?.find(product => (product.metadata?.serviceId === 'PLATFORM_BASIC' || product.metadata?.serviceId === 'PLATFORM_PREMIUM') && (product.prices.find(price => price.subscription) || noChargesSubscribedProducts?.includes(product.id)));
  return subscribedProduct;
}

export function getSubscribedCompanyLicense (products, customer) {
  const noChargesSubscribedProducts = customer?.metadata?.noCharges === 'true' ? customer?.metadata?.noCharges_subscribedProducts : undefined;
  const subscribedProduct = products?.find(product => (product.metadata?.serviceId === 'COMPANY_LICENSE') && (product.prices.find(price => price.subscription) || noChargesSubscribedProducts?.includes(product.id)));
  return subscribedProduct;
}
