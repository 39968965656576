import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
  style = {},
  fill = '#fff',
  height = '100%',
  className = 'DAA',
  viewBox = '0 0 24 24'
}) => (
  <svg width={height}
    style={style}
    height={height}
    viewBox={viewBox}
    className={`svg-icon DAA ${className || ''}`}><desc>Entity Extraction</desc>
    <g transform="translate(2 5)" fill={`${fill}`}>
      <rect x=".020682" y="-.12263" width="11.55" height="2" rx="1"/>
      <rect y="10" width="4.845" height="2" rx=".73408" strokeWidth=".85679" />
      <rect y="5" width="5.1307" height="2" rx=".77737" strokeWidth=".88169"/>
    </g>
    <path d="m15.341 12.699 2.1758 2.1689v-0.10982a2.0591 2.0591 0 0 0-2.0591-2.0591h-0.11669m-2.9514 0.5491 1.0639 1.0639c-0.03432 0.14414-0.05491 0.28828-0.05491 0.44614a2.0591 2.0591 0 0 0 2.0591 2.0591c0.151 0 0.302-0.02059 0.44614-0.05491l1.0639 1.0639c-0.45987 0.2265-0.96779 0.36378-1.51 0.36378a3.4319 3.4319 0 0 1-3.4319-3.4319c0-0.54223 0.13727-1.0502 0.36378-1.51m-3.7956-3.7956 1.8738 1.8738c-1.1325 0.89229-2.0248 2.0591-2.5602 3.4319 1.1874 3.0132 4.1182 5.1478 7.5501 5.1478 1.0639 0 2.0797-0.20591 3.0063-0.57655l0.29514 0.28828 2.0042 2.0042 0.87169-0.87169-12.169-12.169m5.992 2.7455a3.4319 3.4319 0 0 1 3.4319 3.4319c0 0.43928-0.08922 0.86483-0.24709 1.2492l2.0111 2.0111c1.0296-0.85797 1.8532-1.9836 2.3543-3.2603-1.1874-3.0132-4.1182-5.1478-7.5501-5.1478-0.96092 0-1.8807 0.17159-2.7455 0.48046l1.4894 1.4757c0.39123-0.151 0.80992-0.24023 1.2561-0.24023z" strokeWidth=".68637" fill={`${fill}`}/>
  </svg>
);

SVG.propTypes = {
  style: PropTypes.any,
  fill: PropTypes.any,
  height: PropTypes.any,
  className: PropTypes.any,
  viewBox: PropTypes.any
};

export default SVG;
