import { API, Auth } from 'aws-amplify';
import set from 'lodash/set';

export async function getRequestHeaders (headers) {
  const session = await Auth.currentSession();
  const options = {
    headers: Object.assign({}, {
      // Activate following headers when connecting to a local instance of CoreServices
      // 'x-caller-id': 'local:CognitoIdentityCredentials',
      // 'cognito-authentication-type': 'authenticated',
      // 'x-iam-user': 'local:CognitoIdentityCredentials',
      // 'x-cognito-type': 'authenticated',
      // 'x-cognito-id': 'id',
      // 'Content-Type': 'application/json',
      'bs-token': `bearer ${session.idToken.jwtToken}`
    }, headers)
  };
  return options;
}

/**
 * @param tenantId
 * @returns {Promise<Customer>}
 */
export async function getCustomer (tenantId) {
  const request = await getRequestHeaders();
  const path = '/customers/' + tenantId;
  return await API.get('CoreServicesAPI', path, request);
}

export async function setChildRelations (operatorTenantId, parentTenantId, managedCustomerIds, marketplaceAccessCustomerIds) {
  const request = await getRequestHeaders();
  if (operatorTenantId) {
    set(request.headers, 'X-Tenant-Id', operatorTenantId);
  }
  const path = '/customers/' + parentTenantId + '/relations';
  request.body = { childRelationships: {} };
  if (managedCustomerIds) {
    request.body.childRelationships.IS_MANAGING = managedCustomerIds;
  }
  if (marketplaceAccessCustomerIds) {
    request.body.childRelationships.GRANTED_MARKETPLACE_ACCESS_TO = marketplaceAccessCustomerIds;
  }

  return await API.patch('CoreServicesAPI', path, request);
}

export async function togglePipelineFeature (tenantId, shouldShowPipelines) {
  const request = await getRequestHeaders();
  if (tenantId) {
    set(request.headers, 'X-Tenant-Id', tenantId);
  }
  const path = '/customers/' + tenantId + '/features/PIPELINE';
  request.body = {};
  if (shouldShowPipelines) {
    return await API.put('CoreServicesAPI', path, request);
  } else {
    return await API.del('CoreServicesAPI', path, request);
  }
}

export async function toggleEmailConnectorFeature (tenantId, enableEmailConnector) {
  const request = await getRequestHeaders();
  if (tenantId) {
    set(request.headers, 'X-Tenant-Id', tenantId);
  }
  const path = '/customers/' + tenantId + '/features/EMAIL_CONNECTOR';
  request.body = {};
  if (enableEmailConnector) {
    return await API.put('CoreServicesAPI', path, request);
  } else {
    return await API.del('CoreServicesAPI', path, request);
  }
}

/**
 * @param tenantId
 * @returns {Promise<Customer>}
 */
export async function getCustomerAsOperator (tenantId) {
  const request = await getRequestHeaders();
  if (tenantId) {
    set(request.headers, 'X-Tenant-Id', tenantId);
  }
  const path = '/customers/' + tenantId;
  return await API.get('CoreServicesAPI', path, request);
}

/**
 * @typedef {object} CustomerReference
 * @typedef {object} Customer
 * @property {string} tenantId
 * @property {string} customerId
 * @property {string} orgId
 * @property {string} name
 * @property {string} vatId
 * @property {string} industry
 * @property {string} createdAt
 * @property {string} modifyDate
 * @property {object} billingContact
 * @property {string} billingContact.email
 * @property {string} billingContact.city
 * @property {string} billingContact.country
 * @property {string} billingContact.line1
 * @property {string} billingContact.line2
 * @property {string} billingContact.postalCode
 * @property {string} billingContact.contactName
 * @property {object} contactInformation
 * @property {string} contactInformation.email
 * @property {string} contactInformation.phone
 * @property {string} contactInformation.city
 * @property {string} contactInformation.country
 * @property {string} contactInformation.line1
 * @property {string} contactInformation.postalCode
 * @property {string} contactInformation.contactName
 * @property {string} language
 * @property {boolean} allowManualPayment
 * @property {boolean} noCharges
 * @property {boolean} regulationStatusAccept
 * @property {boolean} regulated
 * @property {boolean} allowPublish
 * @property {CustomerReference[]} managedCustomerReferences
 * @property {boolean} manualPaymentDefault
 */
