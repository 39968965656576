
export function isSmall () {
  return document.body.offsetWidth < 960;
}

export function isMedium () {
  return document.body.offsetWidth >= 960 && document.body.offsetWidth < 1280;
}

export function isLarge () {
  return document.body.offsetWidth >= 1280 && document.body.offsetWidth < 1470;
}

export function isXLarge () {
  return document.body.offsetWidth >= 1470;
}

export function hasTouchSupport () {
  const supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
  return supportsTouch;
}
