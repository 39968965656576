import { v4 as uuid } from 'uuid';
import { Storage } from 'aws-amplify';

/**
 * S3-Path:
 * buildsimple-eu-content-repository-{env}/{tenantId}/protected/training/{serviceName}/{modelName}/{classification}/{sessionUUID}/{randomUUID}/{fileName}
 */
export async function uploadFiles (tenant, keyPrefix, files, metadata, progressCb, docClass, tagging) {
  const start = Date.now();
  let promiseResponses = [];
  const pageSize = 100;

  console.log('uploadFiles: ', tenant, keyPrefix, files, metadata, progressCb, docClass);

  configureStorage(tenant);
  const totalProgress = new Map();
  const progressPerFile = 1 / files.length;

  const fileArray = Array.from(files);

  for (let i = 0; i < fileArray.length; i = i + pageSize) {
    const nextPage = fileArray.slice(i, Math.min(i + pageSize, fileArray.length));

    let fileProgress = 0;
    const storagePromises = [];
    for (const [j, file] of nextPage.entries()) {
      const s3Key = `${keyPrefix}/${uuid()}/${file.name}`;
      const metadataCopy = { ...metadata };
      metadataCopy.fileName = encodeURIComponent(file.name);
      storagePromises[fileProgress] = Storage.put(
        s3Key,
        file,
        {
          level: 'public',
          contentType: file.type,
          metadata: metadataCopy,
          tagging,

          progressCallback (progress) {
            if (progressCb && (progress.loaded !== progress.total || progress.total > 400)) {
              totalProgress.set(i + j, progress.loaded / progress.total);
              progressCb({
                totalProgress: Array.from(totalProgress.values()).reduce(function (sum, current) { return sum + current; }, 0) * progressPerFile,
                singleProgress: progress.loaded / progress.total,
                files,
                filesUploaded: fileProgress,
                docClass
              });
            }
          }
        });
      fileProgress++;
    }
    const promiseResponse = await Promise.all(storagePromises);
    promiseResponses = promiseResponses.concat(promiseResponse);
  }
  const end = Date.now();
  const took = end - start;
  console.log('Upload of ' + fileArray.length + ' documents took ' + took + ' ms.');
  return promiseResponses;
}

export function configureStorage (tenantId) {
  Storage.configure({
    customPrefix: {
      public: `${tenantId}/protected/`
    }
  });
}
